import { AutoComplete } from '@xbotvn/react-ui/components';
import styled from 'styled-components';

export const StyledAutocomplete = styled(AutoComplete)`
  width: 282px;
  @media (max-width: 599px) {
    width: 80%;
  }
  .PrivateNotchedOutline-root-3,
  .MuiOutlinedInput-notchedOutline {
    border: unset !important;
  }
  &.MuiInputBase-root {
    border: unset;
  }
  .MuiAutocomplete-clearIndicator {
    display: none;
  }
  .MuiFormControl-root,
  .MuiInputBase-root {
    border: none;
  }
  .MuiInputBase-input {
    font-size: 1rem;
    font-weight: bold;
  }
  .MuiAutocomplete-endAdornment {
    right: 0 !important;
  }
`;
