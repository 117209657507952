import { Icon, Typography } from '@xbotvn/react-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';

import packageJson from '../../../package.json';
import { COMPANY } from '../../config';
import * as Styles from './styles';

const Footer = () => {
  const { product } = useSelector(({ catalogs }) => ({
    product: catalogs?.system?.product?.data ?? {},
  }));
  return (
    <Styles.Container>
      <Styles.StyledBoxContact>
        <ul>
          <li>
            <a href={product.guide} target="_blank" rel="noreferrer">
              <Icon style={{ fontSize: '0.875rem', marginRight: 10 }}>book</Icon>
              <Typography variant="caption">Sách HDSD</Typography>
            </a>
          </li>
          <li>
            <a href="tel:02873003588">
              <Icon style={{ fontSize: '0.875rem', marginRight: 10 }}>call</Icon>
              <Typography variant="caption">028 7300 3588</Typography>
            </a>
          </li>
          <li>
            <a href="mailto:chamsoc@xbot.com.vn">
              <Icon style={{ fontSize: '0.875rem', marginRight: 10 }}>email</Icon>
              <Typography variant="caption">chamsoc@xbot.com.vn</Typography>
            </a>
          </li>
        </ul>
      </Styles.StyledBoxContact>
      <Styles.CopyrightTypography variant="caption">
        {`FBOT v${packageJson.version} - © 2020, ${COMPANY.toUpperCase()} Technology JSC.`}
        <br style={{ display: { xs: 'block', sm: 'none' } }} /> All Rights Reserved.
      </Styles.CopyrightTypography>
      {window.fcWidget ? (
        <Styles.StyledBoxChat>
          <Typography variant="caption">
            Bấm nút Hỗ trợ để được <br /> chăm sóc đầy đủ nhất
          </Typography>
          <Styles.StyledButton
            size="small"
            onClick={() => window.fcWidget.open()}
            color="primary"
            startIcon={<Icon>comment</Icon>}
          >
            Hỗ trợ
          </Styles.StyledButton>
        </Styles.StyledBoxChat>
      ) : null}
    </Styles.Container>
  );
};

export default Footer;
