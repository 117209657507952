import { intersection, set } from '@xbotvn/utils/collection';
import { startOfDay } from '@xbotvn/utils/date';

import { findChildFolders } from '../screens/Folders/utils';

export const getYear = (storeType, overwrite = '') => {
  if (['year', 'document'].includes(storeType)) {
    return overwrite || new Date().getFullYear();
  }
  return 0;
};

export const getLegacyFolderUnit = (folderUnits, id) =>
  Object.values(folderUnits).find(({ legacyID }) => legacyID === id);

export const getLegacyProductUnit = (units, unitID) =>
  Object.values(units ?? {}).find(({ products }) =>
    products.find(({ product, id }) => product === 'fbot' && id === unitID)
  );

export function getCommonSharesFolders(id, folders, shares) {
  const children = findChildFolders(id, folders);
  return children
    .filter(({ shares: cShares }) => intersection(shares, cShares).length)
    .map(({ id: fid }) => fid);
}
export const getCurrentYear = () => {
  let currentYear = new Date().getFullYear();
  // The date to start new school year is 05/09 ( septemper 5) of current year.
  const dateToKeepCurrentYear = startOfDay(new Date(`9/5/${currentYear}`)).getTime();
  const currentDate = startOfDay(new Date()).getTime();
  if (currentDate < dateToKeepCurrentYear) {
    currentYear -= 1;
  }
  return currentYear;
};

export const arrayToCollection = (arr, id, options = {}) => {
  const result = {};
  arr.forEach((item) => {
    if (item[id]) {
      set(result, item[id], {});
      if (options.preserve) set(result, `${item[id]}.${id}`, item[id]);
      Object.entries(item).forEach(([key, value]) => {
        if (value !== null) {
          if (!(options.skip ?? []).includes(key)) set(result, `${item[id]}.${key}`, value);
        }
      });
    }
  });
  return result;
};

export function getFolderStructure(id, folders) {
  const results = [];
  const childFolders = Object.values(folders).filter(({ parent }) => parent === id);
  if (childFolders.length) {
    childFolders.forEach(({ id: fid, name, storeType }) => {
      const pushed = {
        name,
        storeType,
      };

      const cfolders = getFolderStructure(fid, folders);
      if (cfolders.length) pushed.folders = cfolders;
      results.push(pushed);
    });
  }
  return results;
}

export function getUnitAdministeredCity(unit, cities) {
  const { province, district } = unit;

  const getCleanedName = (name) => {
    const replacements = ['Thành phố', 'Tỉnh', 'Thành Phố', 'Quận', 'Huyện'];
    const shortedReplacements = ['TP.', 'T.', 'TP.', 'Q.', 'H.'];
    let cleaned = name;
    replacements.forEach((replacement, index) => {
      cleaned = cleaned.replace(replacement, shortedReplacements[index]);
    });
    return cleaned;
  };

  const city = getCleanedName(cities?.[province]?.name ?? '');
  const districtName = getCleanedName(cities?.[province]?.districts?.[district]?.name ?? '');

  return districtName ? `(${districtName}-${city})` : city;
}

export const formatError = (error) => {
  if (error?.response?.data?.error) {
    return error.response.data.error;
  }
  if (error?.response?.data?.message) {
    return error.response.data.message;
  }

  if (error?.response?.data) {
    return error.response.data;
  }
  return error?.message || 'Đã có lỗi xảy ra';
};
