import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

function Fortify({ onClose, onContinue }) {
  const pecRef = useRef(null);

  useEffect(() => {
    if (pecRef.current) {
      pecRef.current.filters = {
        expired: false,
        onlyWithPrivateKey: true,
        keyUsage: ['digitalSignature'],
      };
      pecRef.current.addEventListener('continue', async (event) => {
        onContinue(event.detail);
      });
      pecRef.current.addEventListener('cancel', () => {
        onClose();
      });
    }
  }, [onClose, onContinue]);

  return <peculiar-fortify-certificates ref={pecRef} />;
}

Fortify.propTypes = {
  onClose: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
};

export default Fortify;
