import {
  AppBar as UAppBar,
  Box,
  Button,
  Icon,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
} from '@xbotvn/react-ui/core';
import { colors } from '@xbotvn/react-ui/styles';
import { trim } from '@xbotvn/utils/string';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { handleQueryFiles } from '../../redux/actions/files';
import { handleOpenSidePanel } from '../../redux/actions/settings';
import * as Icons from '../Icons';
import Units from '../Units';
import UnitSelection from '../UnitSelection';
import Documents from './Documents';
import Profile from './Profile';
import * as Styles from './styles';
import Unit from './Unit';

function AppBar() {
  const { email, isAdmin, isStaff, photoURL, displayName, product } = useSelector(
    ({ user, catalogs }) => ({
      ...user,
      product: catalogs?.system?.product?.data ?? {},
    })
  );
  const dispatch = useDispatch();

  const [showDialog, setShowDialog] = useState();
  const [search, setSearch] = useState('');
  const [menuAnchorEl, setMenuAnchorEl] = useState();

  const dialog = useMemo(() => {
    const props = {
      onClose: () => setShowDialog(),
    };
    switch (showDialog) {
      case 'profile':
        return <Profile {...props} />;
      case 'unit':
        return <Unit {...props} />;
      case 'units':
        return <Units {...props} quickAccess />;
      case 'documents':
        return <Documents {...props} />;
      default:
        return null;
    }
  }, [showDialog]);

  if (isStaff) {
    return (
      <UAppBar
        position="static"
        style={{ boxShadow: 'none', background: 'white', margin: '12px 0' }}
      >
        {dialog}
        <Menu
          open={Boolean(menuAnchorEl)}
          anchorEl={menuAnchorEl}
          keepMounted
          onClose={() => setMenuAnchorEl()}
        >
          <MenuItem
            onClick={() => {
              setShowDialog('documents');
              setMenuAnchorEl();
            }}
          >
            Thư viện văn bản
          </MenuItem>
          <MenuItem
            onClick={() => {
              setShowDialog('unit');
              setMenuAnchorEl();
            }}
          >
            Quản trị
          </MenuItem>
          <MenuItem onClick={() => window.open(product.guide, 'Hướng dẫn sử dụng', 'location:no')}>
            Sách hướng dẫn
          </MenuItem>
        </Menu>
        <Styles.StyledToolbar>
          <Box flexGrow={1} display="flex">
            <Styles.StyledBoxMobile>
              <IconButton onClick={() => dispatch(handleOpenSidePanel(true))}>
                <Icon>menu</Icon>
              </IconButton>
            </Styles.StyledBoxMobile>
            <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
              <UnitSelection />
            </Box>

            <Box flexGrow={1} paddingRight={1} paddingLeft={1}>
              <Styles.StyledTextField
                fullWidth
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Tìm kiếm files"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>search</Icon>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        variant="text"
                        onClick={() => dispatch(handleQueryFiles(trim(search)))}
                      >
                        Tìm kiếm
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
          <Styles.StyledBoxDesktop>
            {isStaff ? (
              <Button
                style={{ marginRight: 2 }}
                size="small"
                color="secondary"
                onClick={() => setShowDialog('documents')}
                startIcon={<Icon>book</Icon>}
              >
                Thư viện văn bản
              </Button>
            ) : null}
            {isAdmin ? (
              <Button
                size="small"
                color="secondary"
                onClick={() => setShowDialog('unit')}
                startIcon={<Icons.XUser stroke={colors.primary[400]} />}
              >
                Quản trị
              </Button>
            ) : null}
          </Styles.StyledBoxDesktop>
          {/* isXbotAccount ? (
            <IconButton
              style={{ marginRight: 7 }}
              size="small"
              color="primary"
              onClick={() => setShowDialog('units')}
            >
              <Icon>display_settings</Icon>
            </IconButton>
          ) : null
          */}
          <Styles.StyledAvatar
            alt={displayName || email}
            src={photoURL || ''}
            onClick={() => setShowDialog('profile')}
          />
          <Styles.StyledBoxMobile>
            <IconButton onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
              <Icon>more_vert</Icon>
            </IconButton>
          </Styles.StyledBoxMobile>
        </Styles.StyledToolbar>
      </UAppBar>
    );
  }
  return null;
}

export default AppBar;
