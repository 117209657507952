import { Notification, Panel, Table, Waiting } from '@xbotvn/react-ui/components';
import { Box, Icon, IconButton } from '@xbotvn/react-ui/core';
import axios from 'axios';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { AIO } from '../../../config';

function Documents({ onClose }) {
  const [handling, setHandling] = useState(false);
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    (async () => {
      setHandling(true);
      try {
        const res = await axios.post(`${AIO}/documents/get`, { product: 'fbot' });
        setDocuments(res?.data ?? []);
      } finally {
        setHandling(false);
      }
    })();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Số/Ký hiệu',
        accessor: 'docNo',
        width: 200,
        sticky: 'left',
      },
      {
        Header: 'Tên văn bản',
        accessor: 'name',
        width: 350,
      },
      {
        Header: 'Ngày ban hành',
        accessor: 'date',
        type: 'date',
        width: 200,
      },
      {
        Header: 'Cơ quan ban hành',
        accessor: 'department',
        width: 200,
      },
      {
        Header: ' ',
        accessor: 'file',
        width: 100,
        Cell: ({ cell: { value }, row: { original } }) => (
          <IconButton
            style={{ marginRight: 7 }}
            size="small"
            onClick={async () => {
              setHandling(true);
              try {
                const res = await axios.post(
                  `${AIO}/documents/download`,
                  { product: 'fbot', id: original.id },
                  { responseType: 'blob' }
                );

                if (res?.data) saveAs(new Blob([res.data]), value);
              } catch ({ message }) {
                Notification.error(message);
              } finally {
                setHandling(false);
              }
            }}
          >
            <Icon>download</Icon>
          </IconButton>
        ),
      },
    ],
    []
  );

  return (
    <Panel open anchor="right" onClose={onClose} title="Thư viện văn bản">
      {handling ? <Waiting fullscreen /> : null}
      <Box width={{ xs: '100vw', sm: 'auto' }} px={2}>
        <Table
          columns={columns}
          data={documents}
          getRowId={(row) => row.id}
          disableGlobalFilter
          disableFilters
          disableGroupBy
        />
      </Box>
    </Panel>
  );
}

Documents.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default Documents;
