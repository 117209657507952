import { Box, LinearProgress } from '@xbotvn/react-ui/core';
import styled from 'styled-components';

export const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 500px;
`;
export const Progress = styled(LinearProgress)`
  height: 10px !important;
  border-radius: 5px;
`;
