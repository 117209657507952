import { Avatar, Box, TextField, Toolbar } from '@xbotvn/react-ui/core';
import styled from 'styled-components';

export const GroupedItems = styled(Box).attrs({
  display: 'flex',
  alignItems: 'center',
})``;

export const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  && {
    min-height: 40px;
  }
  @media (max-width: 599px) {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
`;

export const StyledAvatar = styled(Avatar)`
  @media (min-width: 600px) {
    cursor: pointer;
    margin-left: 8px;
    margin-right: 10px;
  }
  @media (max-width: 599px) {
    margin-left: 4px;
  }
`;

export const StyledTextField = styled(TextField)`
  @media (max-width: 599px) {
    .MuiInputAdornment-positionEnd {
      margin-left: 0px !important;
      margin-right: 4px !important;
    }
    .MuiInputAdornment-positionStart {
      margin-left: 8px !important;
      margin-right: 0px !important;
    }
  }
`;

export const StyledBoxMobile = styled(Box)`
  display: none;
  @media (max-width: 599px) {
    display: block;
  }
`;

export const StyledBoxDesktop = styled(Box)`
  display: block;
  @media (max-width: 599px) {
    display: none;
  }
`;
