import { Box, Button, Icon, IconButton } from '@xbotvn/react-ui/core';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import * as Styles from './styles';

function Signature({ onRemove, signature, onSignatureChange }) {
  const signatureRef = useRef();
  const fillBlueSig = () => {
    const canvas = document.createElement('canvas');
    canvas.width = signatureRef.current.naturalWidth;
    canvas.height = signatureRef.current.naturalHeight;
    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.drawImage(signatureRef.current, 0, 0);
    }

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const allPixels = imageData.data;

    // Pen's Hex Color #151B8D to rgb
    const [r, g, b] = [21, 27, 141];
    for (let i = 0, n = allPixels.length; i < n; i += 4) {
      allPixels[i] = r; // R
      allPixels[i + 1] = g; // G
      allPixels[i + 2] = b; // B
    }

    ctx.putImageData(imageData, 0, 0);
    onSignatureChange(canvas.toDataURL('image/png'));
  };

  return (
    <>
      <Styles.ImgDiv>
        <img ref={signatureRef} src={signature} alt="signature" />
        {onRemove ? (
          <IconButton
            style={{
              position: 'absolute',
              right: 0,
            }}
            onClick={onRemove}
            size="small"
          >
            <Styles.ClearIcon>
              <Icon size="small">clear</Icon>
            </Styles.ClearIcon>
          </IconButton>
        ) : null}
      </Styles.ImgDiv>
      {onSignatureChange ? (
        <Box mt={3}>
          <Button
            style={{
              float: 'right',
            }}
            color="primary"
            variant="outlined"
            size="small"
            onClick={() => fillBlueSig()}
          >
            Phủ xanh chữ ký
          </Button>
        </Box>
      ) : null}
    </>
  );
}

Signature.propTypes = {
  onRemove: PropTypes.func,
  signature: PropTypes.string.isRequired,
  onSignatureChange: PropTypes.func,
};

Signature.defaultProps = {
  onRemove: undefined,
  onSignatureChange: undefined,
};

export default Signature;
