import { Notification, Waiting } from '@xbotvn/react-ui/components';
import { Dialog, DialogContent, DialogTitle } from '@xbotvn/react-ui/core';
import { ThemeProvider, Themes } from '@xbotvn/react-ui/styles';
import axios from 'axios';
import Parser from 'html-react-parser';
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch as RouterSwitch } from 'react-router-dom';

import AuthorizedRoute from './AuthorizedRoute';
import { AppBar, Footer } from './components';
import { AIO } from './config';
import { app } from './libs/firebase';
import { handleUpdateSettings } from './redux/actions/settings';
import { handleUserSignedIn } from './redux/actions/user';

const Folders = React.lazy(() => import('./screens/Folders'));
const NotFound = React.lazy(() => import('./screens/NotFound'));
const Welcome = React.lazy(() => import('./screens/Welcome'));
const Expiration = React.lazy(() => import('./screens/Expiration'));

export default function App() {
  const dispatch = useDispatch();
  const {
    settings: { darkMode },
    logged,
    authorizing,
    activeUnit,
  } = useSelector(({ settings, user }) => ({
    settings,
    logged: user.email && user.activeUnit?.id,
    authorizing: user?.authorizing ?? false,
    activeUnit: user?.activeUnit ?? {},
  }));

  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const cached = localStorage.getItem('settings');
    if (cached) {
      const parsed = JSON.parse(cached);
      dispatch(handleUpdateSettings(parsed));
      if (parsed.dark) {
        document.body.style.backgroundColor = '#30404d';
      }
    }
    return app.auth().onAuthStateChanged((user) => {
      dispatch(handleUserSignedIn(user));
    });
  }, []);

  useEffect(() => {
    const { province, district, ward } = activeUnit;
    if (process.env.REACT_APP_STAGE !== 'development' && (province || district || ward)) {
      axios
        .post(`${AIO}/api/notifications`, { product: 'fbot', ward, district, province })
        .then((results) => {
          setNotifications(results?.data ?? []);
        })
        .catch();
    }
  }, [activeUnit]);

  const ready = useMemo(() => !authorizing, [authorizing]);

  return ready ? (
    <ThemeProvider theme={Themes.default[darkMode ? 'dark' : 'light']}>
      {logged && notifications.length ? (
        <Dialog
          onClose={() => setNotifications((prev) => prev.shift())}
          open
          maxWidth="md"
          fullWidth
        >
          <DialogTitle
            title={notifications[0].title}
            onClose={() => setNotifications((prev) => prev.shift())}
          />
          <DialogContent>{Parser(notifications[0].content)}</DialogContent>
        </Dialog>
      ) : null}
      <BrowserRouter>
        <Suspense fallback={<Waiting fullscreen />}>
          {['/expiration'].includes(window.location?.pathname) ? null : <AppBar />}
          <RouterSwitch>
            <Route exact path="/">
              <Welcome />
            </Route>

            <Route exact path="/welcome">
              <Welcome />
            </Route>
            <AuthorizedRoute exact path="/folders/:unit/:folder" component={Folders} />
            <Route exact path="/expiration">
              <Expiration />
            </Route>
            <Route component={() => <NotFound />} />
          </RouterSwitch>
        </Suspense>
      </BrowserRouter>
      <Footer />
      <Notification.Container />
    </ThemeProvider>
  ) : (
    <Waiting fullscreen />
  );
}
