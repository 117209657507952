import { Confirmation, Panel, Waiting } from '@xbotvn/react-ui/components';
import { Box, Button, Container, Tab, Tabs } from '@xbotvn/react-ui/core';
import { colors } from '@xbotvn/react-ui/styles';
import { cloneDeep, getUniqueKey, set, uniq, unset } from '@xbotvn/utils/collection';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { handleUpdateUnit } from '../../../redux/actions/user';
import * as Icons from '../../Icons';
import Staff from '../../Staff';
import Groups from './Groups';
import Group from './Groups/Group';
import Staffs from './Staffs';
import * as Styles from './styles';

function Unit({ onClose }) {
  const dispatch = useDispatch();
  const {
    staffs: initStaffs,
    admins: initAdmins,
    groups: initGroups,
    handling,
  } = useSelector(({ user }) => ({
    staffs: user.activeUnit?.products?.fbot?.staffs ?? [],
    admins: user.activeUnit?.products?.fbot?.admins ?? [],
    groups: user.activeUnit?.products?.fbot?.groups ?? {},
    handling: user.handling ?? false,
  }));

  const [activeTab, setActiveTab] = useState('staffs');
  const [newStaff, setNewStaff] = useState(false);
  const [newGroup, setNewGroup] = useState(false);
  const [staffs, setStaffs] = useState(initStaffs);
  const [admins, setAdmins] = useState(initAdmins);
  const [groups, setGroups] = useState(initGroups);
  const [confirmation, setConfirmation] = useState();

  const action = useMemo(() => {
    const props = {
      color: 'secondary',
      style: { marginRight: 10 },
    };
    switch (activeTab) {
      case 'staffs':
        return (
          <Button
            {...props}
            onClick={() => setNewStaff(true)}
            startIcon={<Icons.XUser stroke={colors.primary[400]} />}
          >
            Thêm Nhân Sự
          </Button>
        );
      case 'groups':
        return (
          <Button
            {...props}
            onClick={() => setNewGroup(true)}
            startIcon={<Icons.XGroup stroke={colors.primary[400]} />}
          >
            Thêm Nhóm
          </Button>
        );
      default:
        return null;
    }
  }, [activeTab]);

  const content = useMemo(() => {
    switch (activeTab) {
      case 'staffs':
        return (
          <Staffs
            staffs={staffs}
            admins={admins}
            onChange={(email, admin) => {
              if (admin === undefined) {
                setStaffs((prevStaffs) => prevStaffs.filter((e) => e !== email));
                setAdmins((prevAdmins) => prevAdmins.filter((e) => e !== email));
                setGroups((prevGroups) => {
                  const cloned = {};
                  Object.entries(prevGroups).forEach(([gid, values]) => {
                    cloned[gid] = {
                      name: values?.name ?? gid,
                      staffs: (values?.staffs ?? []).filter((e) => e !== email),
                    };
                  });
                  return cloned;
                });
              } else {
                setAdmins((prevAdmins) => {
                  const newAdmins = prevAdmins.filter((e) => e !== email);
                  if (admin) newAdmins.push(email);
                  return newAdmins;
                });
                setStaffs((prevStaffs) => {
                  const newStaffs = prevStaffs.filter((e) => e !== email);
                  if (!admin) newStaffs.push(email);
                  return newStaffs;
                });
              }
            }}
          />
        );
      case 'groups':
        return (
          <Groups
            staffs={staffs}
            groups={groups}
            onChange={(gid, name, emails) => {
              setGroups((prevGroups) => {
                const cloned = cloneDeep(prevGroups);
                if (!name) unset(cloned, gid);
                else set(cloned, gid, { name, staffs: emails || [] });
                return cloned;
              });
            }}
          />
        );
      default:
        return null;
    }
  }, [activeTab, staffs, admins, groups]);

  return (
    <Panel
      anchor="right"
      open
      onClose={onClose}
      title="Cấu Hình Đơn Vị"
      actions={
        <Box display="flex" justifyContent="flex-end" width={1}>
          {action}
          <Button
            color="primary"
            onClick={() => {
              dispatch(
                handleUpdateUnit(staffs, admins, groups, (err) => {
                  if (!err) onClose();
                })
              );
            }}
            startIcon={<Icons.XSave stroke="white" />}
          >
            Cập Nhật
          </Button>
        </Box>
      }
    >
      {handling ? <Waiting fullscreen /> : null}
      {newStaff ? (
        <Staff
          onClose={(email, admin) => {
            if (email) {
              if (admin) setAdmins((prevAdmins) => uniq([...prevAdmins, email]));
              else setStaffs((prevStaffs) => uniq([...prevStaffs, email]));
            }
            setNewStaff();
          }}
        />
      ) : null}
      {newGroup ? (
        <Group
          groups={groups}
          staffs={staffs}
          onClose={(name, members = []) => {
            if (name) {
              setGroups((prevGroups) => ({
                ...prevGroups,
                [getUniqueKey(Object.keys(prevGroups))]: {
                  name,
                  staffs: members,
                },
              }));
            }
            setNewGroup();
          }}
        />
      ) : null}
      {confirmation ? (
        <Confirmation {...confirmation} severity="warning" onClose={() => setConfirmation()} />
      ) : null}

      <Box width={{ xs: '100vw', sm: 820 }}>
        <Styles.UnitContainer>
          <Tabs
            value={activeTab}
            onChange={(e, value) => setActiveTab(value)}
            indicatorColor={colors.primary[600]}
            textColor="primary"
            centered
          >
            <Tab label="Nhân Sự" value="staffs" />
            <Tab label="Nhóm" value="groups" />
          </Tabs>
          <Container style={{ marginTop: 20 }}>{content}</Container>
        </Styles.UnitContainer>
      </Box>
    </Panel>
  );
}

Unit.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default Unit;
