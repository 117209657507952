import './style.css';

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from '@xbotvn/react-ui/core';
import { endOfDay, format } from '@xbotvn/utils/date';
import React from 'react';
import { useSelector } from 'react-redux';

import { CONTACTS } from '../../libs/options';

const ExpiredNotification = () => {
  const activeUnit = useSelector(({ user }) => user.activeUnit);
  const [showNotification, setShowNotification] = React.useState(true);
  const dateOfExpiration = format(new Date(activeUnit?.products?.fbot?.license?.expired || 0));

  const date = new Date().getTime();

  const hideUntil = localStorage.getItem('hideUntil') ?? 0;
  if (hideUntil && hideUntil > date) {
    return null;
  }

  return (
    <Dialog open={showNotification} maxWidth="sm" fullWidth>
      <DialogTitle title="Thông báo" />
      <DialogContent>
        <Box>
          <Typography variant="title">Thông báo tự động - Sắp hết hạn sử dụng phần mềm</Typography>
          <Divider className="divider" />
          <Box>
            <Typography variant="body1">Xin chào Quý Thầy/Cô,</Typography>
            <Typography>
              Phần mềm ghi nhận đơn vị {`"${activeUnit.name}"`}{' '}
              <Typography variant="span" color="error">
                sẽ hết hạn sử dụng vào lúc 00:00 ngày {dateOfExpiration}.
              </Typography>
            </Typography>
            <Typography>
              Để tiếp tục sử dụng phần mềm, Quý Thầy/Cô vui lòng liên hệ qua thông tin sau:
            </Typography>
            <List dense className="list">
              {CONTACTS.map((contact, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <ListItem key={index}>
                  <ListItemIcon>
                    <Icon fontSize="10px">arrow_right</Icon>
                  </ListItemIcon>
                  <Typography>{contact}</Typography>
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions className="actions">
        <FormControlLabel
          label="Không hiển thị lại trong ngày"
          onChange={(e) => {
            if (e.target.checked) {
              const hideDate = endOfDay(new Date()).getTime();
              localStorage.setItem('hideUntil', hideDate);
            } else {
              localStorage.removeItem('hideUntil');
            }
          }}
          control={<Checkbox color="primary" inputProps={{ 'aria-label': 'secondary checkbox' }} />}
        />
        <Button
          onClick={() => {
            setShowNotification(false);
          }}
          color="primary"
        >
          Đóng
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ExpiredNotification.propTypes = {};

export default ExpiredNotification;
