import { Notification } from '@xbotvn/react-ui/components';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router-dom';

import { handleUserSignOut } from './redux/actions/user';

const AuthorizedRouter = ({ path, component: Component }) => {
  const { isStaff, email, activeUnit } = useSelector(({ user }) => ({
    isStaff: user.isStaff,
    email: user.email,
    activeUnit: user.activeUnit,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.location.host === 'fbot.xbot.com.vn') {
      window.location.replace('https://fbot.esoft.edu.vn/');
    }
  }, []);
  const isExpired = Boolean(
    activeUnit?.products?.fbot?.license?.expired &&
      new Date(activeUnit?.products?.fbot?.license?.expired) < new Date()
  );

  if (isExpired) {
    return (
      <Route
        exact
        path={path}
        render={(props) => (
          <Redirect to={{ pathname: '/expiration', state: { from: props.location } }} />
        )}
      />
    );
  }

  if (isStaff) {
    return <Route exact path={path} render={(props) => <Component {...props} />} />;
  }
  if (email) {
    Notification.error('Tài khoản chưa được cấp quyền sử dụng phần mềm.');
    dispatch(handleUserSignOut());
  }

  return (
    <Route
      exact
      path={path}
      render={(props) => (
        <Redirect to={{ pathname: '/welcome', state: { from: props.location } }} />
      )}
    />
  );
};

AuthorizedRouter.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
};

export default withRouter(AuthorizedRouter);
