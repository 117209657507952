import { Box } from '@xbotvn/react-ui/core';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { COMPANY, RESOURCES } from '../../config';
import { getUnitAdministeredCity } from '../../libs/utils';
import { handleSwitchUnit } from '../../redux/actions/user';
import * as Styles from './styles';

const UnitSelection = () => {
  const { activeUnit, units, cities, isXbotAccount, citiesHandling } = useSelector(
    ({ user, catalogs }) => ({
      activeUnit: user.activeUnit,
      units: user.units,
      cities: catalogs?.system?.cities?.data ?? {},
      citiesHandling: catalogs?.system?.cities?.handling,
      isXbotAccount: user.isXbotAccount,
    })
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const unitsOptions = useMemo(() => {
    const results = {};
    if (units) {
      if (citiesHandling) {
        units.forEach(({ id, name }) => {
          results[id] = name;
        });
      } else {
        units.forEach(({ id, name, province, district }) => {
          const city = isXbotAccount ? getUnitAdministeredCity({ province, district }, cities) : '';
          results[id] = city ? `${name} ${city}` : name;
        });
      }
    }
    return results;
  }, [units, isXbotAccount, cities, citiesHandling]);

  const handleChangeUnit = (id) => {
    const isValidId = units.find(({ id: unitId }) => unitId === id);
    if (isValidId && id !== activeUnit) {
      dispatch(handleSwitchUnit({ activeUnit: id }, () => window.location.reload()));
      history.push(`/folders/${id}/unit`);
    }
  };

  return activeUnit && units && !citiesHandling ? (
    <Box
      style={{
        display: 'flex',
        background: 'white',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Link to="/">
        <img style={{ width: 50, height: 'auto' }} src={`${RESOURCES}/${COMPANY}.png`} alt="logo" />
      </Link>
      <Styles.StyledAutocomplete
        value={activeUnit?.id}
        options={Object.keys(unitsOptions)}
        getOptionLabel={(value) => unitsOptions?.[value] ?? ''}
        onChange={(e, value) => value && handleChangeUnit(value)}
        inputProps={{ variant: 'outlined' }}
      />
    </Box>
  ) : null;
};
export default UnitSelection;
