import { Confirmation, Table } from '@xbotvn/react-ui/components';
import { IconButton } from '@xbotvn/react-ui/core';
import { colors } from '@xbotvn/react-ui/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import * as Icons from '../../../Icons';

function Staffs({ staffs, admins, onChange }) {
  const skipPageResetRef = useRef();
  const { userEmail, members } = useSelector(({ user }) => ({
    userEmail: user.email,
    members: user.activeUnit?.members ?? [],
  }));

  const [confirmation, setConfirmation] = useState();

  const columns = useMemo(
    () => [
      {
        Header: 'Họ và Tên',
        accessor: 'displayName',
        width: 220,
        disableEdit: true,
        sticky: 'left',
      },
      {
        Header: 'Email',
        accessor: 'email',
        disableEdit: true,
        width: 240,
      },
      {
        Header: 'Giới Tính',
        accessor: 'gender',
        lookup: {
          m: 'Nam',
          f: 'Nữ',
        },
        width: 100,
        disableEdit: true,
      },
      {
        Header: 'Quản Trị',
        accessor: 'admin',
        type: 'checkbox',
        width: 90,
      },
      {
        Header: ' ',
        accessor: 'id',
        Cell: ({ cell: { value } }) =>
          value !== userEmail ? (
            <IconButton size="small" onClick={() => setConfirmation(value)}>
              <Icons.XBin stroke={colors.red[400]} />
            </IconButton>
          ) : null,
        action: true,
        width: 50,
        sticky: 'right',
      },
    ],
    [userEmail]
  );

  const rows = useMemo(() => {
    const allStaffs = [...staffs, ...admins];
    const initMembers = [];
    members.forEach(({ email: staff, ...info }) => {
      if (allStaffs.includes(staff)) {
        initMembers.push({
          id: staff,
          email: staff,
          admin: admins.includes(staff),
          ...info,
          gender: info.male ? 'm' : 'f',
        });
      }
    });
    allStaffs.forEach((staff) => {
      if (!initMembers.find(({ email }) => email === staff)) {
        initMembers.push({
          id: staff,
          email: staff,
          admin: admins.includes(staff),
          gender: 'f',
        });
      }
    });

    return initMembers;
  }, [staffs, admins, members]);

  useEffect(() => {
    skipPageResetRef.current = false;
  }, [rows]);

  return (
    <>
      {confirmation ? (
        <Confirmation
          severity="warning"
          description="Sau khi xóa thì tài khoản sẽ không có quyền đăng nhập."
          onClose={() => setConfirmation()}
          primaryAction={() => {
            onChange(confirmation);
            setConfirmation();
          }}
        />
      ) : null}
      <Table
        columns={columns}
        data={rows}
        updateHandler={(rowId, column, newValue) => {
          onChange(rowId, newValue);
          skipPageResetRef.current = true;
        }}
        disableGlobalFilter
        disableGroupBy
        autoResetPage={!skipPageResetRef.current}
        autoResetExpanded={!skipPageResetRef.current}
        autoResetGroupBy={!skipPageResetRef.current}
        autoResetSelectedRows={!skipPageResetRef.current}
        autoResetSortBy={!skipPageResetRef.current}
        autoResetFilters={!skipPageResetRef.current}
        autoResetRowState={!skipPageResetRef.current}
        height={window.innerHeight - 260}
        usePagination={false}
        rowHeight={56}
      />
    </>
  );
}

Staffs.propTypes = {
  onChange: PropTypes.func.isRequired,
  staffs: PropTypes.arrayOf(PropTypes.string),
  admins: PropTypes.arrayOf(PropTypes.string),
};

Staffs.defaultProps = {
  staffs: [],
  admins: [],
};

export default Staffs;
