import { Notification } from '@xbotvn/react-ui/components';
import { clone } from '@xbotvn/utils/collection';
import { END, eventChannel } from 'redux-saga';
import { all, call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { callAPI, graphQLCaller, uploadFiles } from '../../libs/backend';
import { formatError, getYear } from '../../libs/utils';
import { getNeartParentPermission, getRootParent, traceToRoot } from '../../screens/Folders/utils';
import { FILES, FOLDERS } from './constants';

function* update(folder, data = {}) {
  yield put({
    type: FILES.update,
    folder,
    data,
  });
}

function* updateSearch(data = {}) {
  yield put({
    type: FILES.search,
    data,
  });
}

function* getFiles({ folder, rootParent = '' }) {
  const user = (yield select()).user ?? {};
  const unitID = user.activeUnit?.id ?? '';
  const province = user.activeUnit?.province ?? '';
  const legacyID = user.activeUnit?.legacyID ?? '';
  const storeType = (yield select())?.folders?.data?.[folder]?.storeType ?? 'permanent';
  const selectedYear = (yield select())?.folders?.year ?? '';
  const connectors = (yield select())?.user?.activeUnit?.products?.fbot?.connectors ?? [];
  const folders = (yield select())?.folders?.data ?? {};
  const year = getYear(storeType, selectedYear);
  try {
    const connector = connectors.find((e) => folder.startsWith(`${e}_`));
    const results = {};
    if (connector) {
      if (folders?.[folder]) {
        const { code, parent, originalUnitID, key } = folders?.[folder] ?? {};

        const connectorFiles = yield callAPI('connector/files', {
          folder: folder?.replace(`${connector}_`, ''),
          year: selectedYear,
          connector,
          code,
          parent: parent?.replace(`${connector}_`, ''),
          unitId: originalUnitID,
          province,
          key,
        });
        connectorFiles.forEach(({ keys, name, actions, id, size, created, key: fileKey }) => {
          let fileID = id;
          if (['abot', 'elibot'].includes(connector)) fileID = keys.join('_');
          results[fileID] = {
            id: fileID,
            keys,
            name,
            unit: connector,
            folder,
            year,
            actions,
            size,
            created,
            key: fileKey,
          };
        });
      }
    } else {
      const { files } = yield graphQLCaller(
        'files',
        `{
            files(unitID: "${unitID}", folder: "${folder}", year: ${year}, rootParent: "${rootParent}") {
              unit
              id
              folder
              year
              name
              hyberlink
              created {
                user
                time
              }
              modified {
                user
                time
              }
              size
              comment {
                user
                time
                content
              }
              labels
              removed
              description
              pageCount
              authority
              date
              category
              docType
              docNo
              hyberlink
            }
          }`
      );
      let total = 0;
      (files || []).forEach(({ id, size, ...rest }) => {
        let permissions = folders?.[folder]?.permissions ?? [];
        if (!permissions.length) {
          const nearestParentPermissions = getNeartParentPermission(folder, folders, user);
          if (nearestParentPermissions && nearestParentPermissions.length)
            permissions = nearestParentPermissions;
          else {
            const rootItemFolder = getRootParent(
              folder,
              folders,
              (folders?.[folders]?.shares ?? []).includes(legacyID || unitID)
            );
            permissions = folders[rootItemFolder]?.permissions ?? [];
          }
        }
        results[id] = {
          id,
          size,
          permissions,
          ...rest,
        };
        if (!rest.removed) total += size || 0;
      });
      if (folder !== 'unit') {
        yield put({
          type: FOLDERS.size,
          id: folder,
          size: total,
        });
      }
    }
    yield* update(folder, results);
  } catch (error) {
    Notification.error(formatError(error));
    yield* update(folder);
  }
}

function* queryFiles({ query, options }) {
  if (query) {
    const unitID = (yield select()).user.activeUnit?.id ?? '';
    try {
      const { search } = yield graphQLCaller(
        'files',
        `
        query search($unitID: String!, $query: String!, $options: OptionsInput) {
          search(unitID: $unitID, query: $query, options: $options) {
            unit
            id
            folder
            year
            name
            created {
              user
              time
            }
            modified {
              user
              time
            }
            size
            comment {
              user
              time
              content
            }
            labels
            removed
            description
            pageCount
            authority
            date
            category
            docType
            docNo
          }
        }
      `,
        {
          unitID,
          query,
          options,
        }
      );

      const results = {};
      (search || []).forEach(({ id, size, ...rest }) => {
        results[id] = {
          id,
          size,
          ...rest,
        };
      });
      yield* updateSearch(results);
    } catch (error) {
      Notification.error(formatError(error));
      yield* updateSearch();
    }
  }
}

function* getFolders(folder, rootParent) {
  const isSharedFolder = ((yield select())?.folders?.data?.[folder]?.shares ?? []).length;
  const isReports = ((yield select())?.folders?.data?.[folder]?.reports ?? []).length;
  yield put({
    type: FOLDERS.handlers.get,
    category: '',
    rootParent,
  });
  if (isReports) {
    yield put({
      type: FOLDERS.handlers.get,
      category: 'reports',
    });
  }
  if (isSharedFolder) {
    yield put({
      type: FOLDERS.handlers.get,
      category: 'shares',
    });
  }
}

function* removeFiles({ folder, ids, removeAll, onComplete }) {
  const unitID = (yield select()).user.activeUnit?.id ?? '';
  const legacyID = (yield select()).user.activeUnit?.legacyID ?? '';
  const connectors = (yield select())?.user?.activeUnit?.products?.fbot?.connectors ?? [];

  const folders = (yield select())?.folders?.data ?? {};
  try {
    const connector = connectors.find((e) => folder.startsWith(`${e}_`));
    const rootParent = getRootParent(
      folder,
      folders,
      (folders?.[folder]?.shares ?? []).includes(legacyID || unitID)
    );
    if (connector) {
      const { key, originalUnitID } = (yield select())?.folders?.data?.[folder] ?? {};
      const files = (yield select())?.files?.data?.[folder] ?? {};
      const removedFiles = ids.map((id) => ({ name: files?.[id]?.name ?? '' }));
      yield callAPI('connector/removeFiles', {
        folder: folder.replace(`${connector}_`, ''),
        connector,
        key,
        unitId: originalUnitID,
        files: JSON.stringify(removedFiles),
      });
    } else {
      yield* ids.map(function* removeFile(id) {
        yield graphQLCaller(
          'files',
          `
        mutation removeFile($unitID: String!, $id: String!, $all: Boolean, $rootParent: String) {
          removeFile(unitID: $unitID, id: $id, all: $all, rootParent: $rootParent)
        }
      `,
          {
            unitID,
            id,
            all: removeAll,
            rootParent,
          }
        );
      });
    }
    Notification.success('Xoá file thành công.', { action: onComplete });
    yield* getFolders(folder);
    yield put({
      type: FILES.handlers.get,
      folder,
      label: '',
      rootParent,
    });
  } catch ({ message }) {
    Notification.error(message, { action: () => onComplete(message) });
    yield* update(unitID, folder);
  }
}

function* writeComment({ folder, id, content, onComplete }) {
  const { id: unitID, legacyID } = (yield select())?.user?.activeUnit ?? {};
  const folders = (yield select())?.folders?.data ?? {};
  const rootParent = getRootParent(
    folder,
    folders,
    (folders?.[folder]?.shares ?? []).includes(legacyID || unitID)
  );
  try {
    yield graphQLCaller(
      'files',
      `
        mutation writeComment($unitID: String!, $id: String!, $content: String, $rootParent: String) {
          writeComment(unitID: $unitID, id: $id, content: $content, rootParent: $rootParent)
        }
      `,
      {
        unitID,
        id,
        content,
        rootParent,
      }
    );
    Notification.success('Cập nhật nhận xét thành công.', { action: onComplete });
    yield put({
      type: FILES.handlers.get,
      folder,
      label: '',
      rootParent,
    });
  } catch ({ message }) {
    Notification.error(message);
    yield* update(unitID, folder);
  }
}

function createUploadChannel(uploadFilesFunc, params, files, connector) {
  return eventChannel((emitter) => {
    const result = uploadFilesFunc(params, files, connector, (percentCompleted) => {
      if (percentCompleted < 100) {
        emitter(percentCompleted);
      }
    });
    result
      .then(() => {
        emitter(100);
        emitter(END);
      })
      .catch((error) => {
        emitter(error);
        emitter(END);
      });
    return () => {};
  });
}

function* uploadFilesSaga(params, files, connector) {
  const uploadChannel = yield call(createUploadChannel, uploadFiles, params, files, connector);
  while (true) {
    const percentCompleted = yield take(uploadChannel);
    yield put({
      type: FILES.handlers.process,
      process: percentCompleted,
    });
    if (percentCompleted === 100) {
      break;
    }
  }
}

function* upload({ folder, files, replace, isSignAction }) {
  const unitID = (yield select()).user.activeUnit?.id ?? '';
  const email = (yield select()).user.email ?? '';
  const legacyID = (yield select()).user.activeUnit?.legacyID ?? '';
  const storeType = (yield select())?.folders?.data?.[folder]?.storeType ?? 'permanent';
  const folders = (yield select())?.folders?.data ?? {};
  const originalUnitID = (yield select())?.folders?.data?.[folder]?.originalUnitID ?? '';
  const key = (yield select())?.folders?.data?.[folder]?.key ?? '';
  const year = getYear(storeType, (yield select())?.folders?.year ?? '');
  const connectors = (yield select())?.user?.activeUnit?.products?.fbot?.connectors ?? [];
  try {
    const connector = connectors.find((e) => folder.startsWith(`${e}_`));
    const rootParent = getRootParent(
      folder,
      folders,
      (folders?.[folder]?.shares ?? []).includes(legacyID || unitID)
    );
    const params = connector
      ? {
          unitId: originalUnitID,
          folder: folder.replace(`${connector}_`, ''),
          connector,
          key,
        }
      : {
          unitID,
          folder,
          year,
          replace,
          extract: storeType === 'document',
          rootParent: rootParent || '',
        };
    yield* uploadFilesSaga(params, files, connector);
    if (folder !== 'unit') {
      const ids = traceToRoot(folder, folders).map(({ id }) => id);
      yield put({
        type: FOLDERS.handlers.update,
        id: [...ids, folder],
        data: {
          modified: {
            time: Date.now(),
            user: email,
          },
        },
        related: false,
        onComplete: () => {},
      });
    }
    yield* getFolders(folder, rootParent);
    yield put({
      type: FILES.handlers.get,
      folder,
      label: '',
      rootParent,
    });
    Notification.success(isSignAction ? 'Đã ký file thành công' : 'Upload files thành công.');
  } catch ({ message }) {
    yield* update();
    Notification.error(message);
  }
}

function* updateFile({ id, folder, data, onComplete }) {
  const { id: unitID, legacyID } = (yield select())?.user?.activeUnit ?? {};
  const folders = (yield select())?.folders?.data ?? {};
  const rootParent = getRootParent(
    folder,
    folders,
    (folders?.[folder]?.shares ?? []).includes(legacyID || unitID)
  );
  try {
    yield graphQLCaller(
      'files',
      `
        mutation updateFile($unitID: String!, $id: String!, $file: FileInput!, $rootParent: String) {
          updateFile(unitID: $unitID, id: $id, file: $file, rootParent: $rootParent)
        }
      `,
      {
        unitID,
        id,
        file: data,
        rootParent,
      }
    );
    yield put({
      type: FILES.file.update,
      folder,
      file: id,
      data,
    });
    Notification.success('Cập nhật file thành công.', { action: onComplete });
  } catch ({ message }) {
    yield* update();
    Notification.error(message);
  }
}
function* createFile({ year, folder, data, onComplete }) {
  const { id: unitID, legacyID } = (yield select())?.user?.activeUnit ?? {};
  const folders = (yield select())?.folders?.data ?? {};
  const rootParent = getRootParent(
    folder,
    folders,
    (folders?.[folder]?.shares ?? []).includes(legacyID || unitID)
  );

  try {
    const { createFile: file } = yield graphQLCaller(
      'files',
      `
        mutation createFile($unitID: String!, $year: Int!, $folder: String!, $file: FileInput!, $rootParent: String) {
          createFile(unitID: $unitID, year: $year, folder: $folder, file: $file, rootParent: $rootParent) {
              unit
              id
              folder
              year
              name
              hyberlink
              created {
                user
                time
              }
              modified {
                user
                time
              }
              size
              comment {
                user
                time
                content
              }
              labels
              description
          }
        }
      `,
      {
        unitID,
        year,
        file: data,
        folder,
        rootParent,
      }
    );

    yield put({
      type: FILES.file.update,
      folder,
      file: file.id,
      data: file,
    });
    Notification.success('Cập nhật file thành công.', { action: onComplete });
  } catch ({ message }) {
    yield* update();
    Notification.error(message);
  }
}

function* batchUpdateFiles({ folderId, files, onComplete }) {
  const { id: unitID, legacyID } = (yield select())?.user?.activeUnit ?? {};
  const folders = (yield select())?.folders?.data ?? {};
  const rootParent = getRootParent(
    folderId,
    folders,
    (folders?.[folderId]?.shares ?? []).includes(legacyID || unitID)
  );
  try {
    yield graphQLCaller(
      'files',
      `
        mutation updateFiles($unitID: String!, $files: [FileInput]!, $rootParent: String) {
          updateFiles(unitID: $unitID, files: $files, rootParent: $rootParent)
        }
      `,
      {
        unitID,
        files,
        rootParent,
      }
    );
    yield put({
      type: FILES.handlers.get,
      folder: folderId,
      rootParent,
    });
    Notification.success('Cập nhật files thành công.', { action: onComplete });
  } catch ({ message }) {
    yield* update();
    Notification.error(message);
  }
}

function* moveFiles({ files, folder, year, onComplete }) {
  const unitID = (yield select())?.user?.activeUnit?.id ?? '';
  const legacyID = (yield select())?.user?.activeUnit?.legacyID ?? '';
  const folders = (yield select())?.folders?.data ?? {};
  const rootParent = getRootParent(
    files[0].folder,
    folders,
    (folders?.[files[0].folder]?.shares ?? []).includes(legacyID || unitID)
  );
  const from =
    files[0].folder === 'unit'
      ? 'Hồ sơ đơn vị'
      : (yield select())?.folders?.data?.[files[0].folder]?.name ?? '';
  const to =
    folder === 'unit' ? 'Hồ sơ đơn vị' : (yield select())?.folders?.data?.[folder]?.name ?? '';
  const log = `${from} đến ${to}`;
  const allFiles = (yield select())?.files?.data ?? {};
  const updateFiles = clone(files);
  files.forEach((file) =>
    Object.entries(allFiles).forEach(([parrent, cfiles]) => {
      Object.values(cfiles).forEach((cFile) => {
        if (
          parrent === file.folder &&
          cFile.name.replace('_signed', '') === file.name.replace('_signed', '') &&
          file.id !== cFile.id
        ) {
          updateFiles.push({ ...cFile });
        }
      });
    })
  );
  try {
    yield graphQLCaller(
      'files',
      `
        mutation moveFiles($unitID: String!, $ids: [String]!, $folder: String!, $year: Int!, $log: String!) {
          moveFiles(unitID: $unitID, ids: $ids, folder: $folder, year: $year, log: $log)
        }
      `,
      {
        unitID,
        ids: updateFiles.map(({ id }) => id),
        folder,
        year,
        log,
      }
    );
    Notification.success('Cập nhật file thành công.', { action: onComplete });
    yield put({
      type: FILES.handlers.get,
      folder: updateFiles[0].folder,
      label: '',
      rootParent,
    });
    yield* getFolders(folder);
  } catch ({ message }) {
    yield* update();
    Notification.error(message);
  }
}
function* moveFilesToPreviousYear({ folders, year, onComplete }) {
  const unitID = (yield select())?.user?.activeUnit?.id ?? '';
  try {
    yield graphQLCaller(
      'files',
      `
        mutation moveFilesToPrevousYear($unitID: String!, $year: Int!, $folders: [String]!) {
          moveFilesToPrevousYear(unitID: $unitID, year: $year, folders: $folders)
        }
      `,
      {
        unitID,
        folders,
        year,
      }
    );
    yield put({
      type: FOLDERS.handlers.get,
      category: '',
    });
    yield put({
      type: FOLDERS.handlers.get,
      category: 'reports',
    });
    yield put({
      type: FOLDERS.handlers.get,
      category: 'shares',
    });
    Notification.success('Dời file thành công.', { action: onComplete });
  } catch ({ message }) {
    onComplete();
    yield* update();
    Notification.error(message);
  }
}

export const handleUploadFiles = (folder, files, replace = false, isSignAction = false) => ({
  type: FILES.handlers.upload,
  folder,
  files,
  replace,
  isSignAction,
});

export const handleGetFiles = (folder, rootParent) => ({
  type: FILES.handlers.get,
  folder,
  rootParent,
});

export const handleQueryFiles = (query, options = {}) => ({
  type: FILES.handlers.search,
  query,
  options,
});

export const handleRemoveFiles = (folder, ids, removeAll, onComplete) => ({
  type: FILES.handlers.remove,
  folder,
  ids,
  removeAll,
  onComplete,
});

export const handleWriteComment = (id, folder, content, onComplete) => ({
  type: FILES.handlers.comment,
  folder,
  id,
  content,
  onComplete,
});

export const handleUpdateFile = (id, folder, data, onComplete) => ({
  type: FILES.handlers.update,
  id,
  folder,
  data,
  onComplete,
});
export const handleCreateFile = (year, folder, data, onComplete) => ({
  type: FILES.handlers.create,
  year,
  folder,
  data,
  onComplete,
});

export const handleBatchUpdateFiles = (folderId, files, onComplete) => ({
  type: FILES.handlers.batch,
  folderId,
  files,
  onComplete,
});

export const handleMoveFiles = (files, folder, year, onComplete) => ({
  type: FILES.handlers.move,
  files,
  folder,
  year,
  onComplete,
});
export const handleMoveFilesToPrevYear = (folders, year, onComplete) => ({
  type: FILES.handlers.moveToPrevYear,
  folders,
  year,
  onComplete,
});

export default function* saga() {
  yield all([
    yield takeEvery(FILES.handlers.upload, upload),
    yield takeEvery(FILES.handlers.create, createFile),
    yield takeEvery(FILES.handlers.get, getFiles),
    yield takeEvery(FILES.handlers.remove, removeFiles),
    yield takeEvery(FILES.handlers.comment, writeComment),
    yield takeEvery(FILES.handlers.update, updateFile),
    yield takeEvery(FILES.handlers.batch, batchUpdateFiles),
    yield takeEvery(FILES.handlers.move, moveFiles),
    yield takeEvery(FILES.handlers.moveToPrevYear, moveFilesToPreviousYear),
    yield takeLatest(FILES.handlers.search, queryFiles),
  ]);
}
