import { Notification } from '@xbotvn/react-ui/components';
import { cloneDeep, compact, flattenDeep, set, uniq, unset } from '@xbotvn/utils/collection';
import { all, put, select, takeEvery } from 'redux-saga/effects';

import { callAPI, graphQLCaller } from '../../libs/backend';
import { arrayToCollection, formatError } from '../../libs/utils';
import {
  getItemPermissions,
  getRootParent,
  hasPermissionToView,
  traceToRoot,
} from '../../screens/Folders/utils';
import { FILES, FOLDERS } from './constants';

function* merge(data = []) {
  yield put({
    type: FOLDERS.merge,
    data,
  });
}

function* update(id, data) {
  yield put({
    type: FOLDERS.update,
    id,
    data,
  });
}

function getChildrenSize(id, folders) {
  const children = folders.filter(({ parent, id: cid }) => cid !== id && parent === id);
  if (children.length) {
    return children.reduce(
      (total, { size, id: cid, sizes }) =>
        total +
        (sizes.reduce((sum, { size: sz }) => sum + sz, 0) + (size || 0)) +
        getChildrenSize(cid, folders),
      0
    );
  }
  return 0;
}

function getRelatedIDs(id, folders) {
  const children = folders.filter(({ parent, id: cid }) => parent === id && id !== cid);
  if (children.length) {
    return uniq([
      id,
      ...children.map(({ id: cid }) => [cid, ...getRelatedIDs(cid, folders)]).flat(),
    ]);
  }
  return [id];
}

function* getFolders({ category, unit: unitFolder = '', folderParent = '', rootParent = '' }) {
  const user = (yield select())?.user ?? {};
  const { activeUnit, isAdmin, isXbotAccount } = user;
  const foldersStore = (yield select())?.folders?.data ?? {};
  const units = (yield select())?.folders?.units ?? {};
  const province = activeUnit?.province;
  if (activeUnit) {
    const year = (yield select())?.folders?.year ?? '';
    const connectors = (yield select())?.user?.activeUnit?.products?.fbot?.connectors ?? [];
    try {
      const results = {};
      if (connectors.includes(category)) {
        const connectorProductId = activeUnit.products?.[category]?.id;
        const connectorUnitId = localStorage.getItem(`${category}_${activeUnit.id}`);
        const data = {
          year,
          connector: category,
          unitId: connectorProductId || connectorUnitId || '',
          province,
        };
        const connectorFolders = yield callAPI('connector/folders', data);
        const firstFolderId = (connectorFolders || [])[0]?.unitId;
        if (!connectorUnitId && (connectorProductId || firstFolderId)) {
          localStorage.setItem(`${category}_${activeUnit.id}`, firstFolderId || connectorProductId);
        } else if (connectorProductId !== (connectorFolders || [])[0]?.unitId) {
          localStorage.setItem(`${category}_${activeUnit.id}`, '');
        }
        (connectorFolders || []).forEach(
          ({ id, name, parent, unitId: originalUnitID, key, code }) => {
            results[`${category}_${id}`] = {
              id: `${category}_${id}`,
              name,
              unit: category,
              parent: `${category}_${parent}`,
              storeType: year ? 'year' : '',
              originalUnitID: connectorProductId || originalUnitID,
              key,
              code,
            };
          }
        );
      } else {
        const unitID = activeUnit.id;
        const { folders } = yield graphQLCaller(
          'folders',
          `{
            folders (unitID: "${unitID}", category: "${category}", parent: "${folderParent}", unitFolder: "${unitFolder}", rootParent: "${rootParent}") {
            id
            unit
            shares
            reports
            expire
            extend {
              unit
              time
            }
            name
            created {
              user
              time
            }
            modified {
              user
              time
            }
            size
            parent
            storeType
            permissions {
              group
              permissions
            }
            labels
            sizes {
              year,
              size
            }
          }
        }`
        );
        const missing = [];
        (folders || []).forEach(({ id, shares, reports, extend, ...rest }) => {
          const ext = {};
          (extend || []).forEach(({ unit, time }) => {
            ext[unit] = time;
          });
          const mergedShares = shares || [];
          if (category === 'shares' && unitFolder) {
            mergedShares.push(activeUnit.legacyID || activeUnit.id);
          }
          results[id] = {
            id,
            shares: mergedShares,
            reports: reports || [],
            extend: ext,
            childrenSize: getChildrenSize(id, folders || []),
            ...rest,
          };

          if (category === 'shares') {
            if (!unitFolder) results[id].rootShares = true;
            else if (!(rest.permissions ?? []).length) {
              results[id].permissions = foldersStore[id]?.permissions ?? [];
            }
          }
          const tmp = [...(mergedShares || []), ...(reports || [])].filter((uid) => !units[uid]);
          if (tmp.length) missing.push(tmp);
          if (rest.unit && activeUnit.id !== rest.unit) missing.push([rest.unit]);
        });

        if (missing.length) {
          const { getProductUnits } = yield graphQLCaller(
            'units',
            `
            query getProductUnits($units: [String]!) {
              getProductUnits(units: $units) {
                id
                legacyID
                name
                type
                district
              }
            }
          `,
            {
              units: uniq(missing.flat()),
            }
          );
          const tmp = {};
          getProductUnits
            .filter((unit) => unit?.id)
            .forEach(({ id, name, type, legacyID, district }) => {
              tmp[id] = { name, type, legacyID, district };
            });
          yield put({
            type: FOLDERS.units,
            units: tmp,
          });
        }

        if (!category && !folders.length && !connectors.includes(category) && isAdmin) {
          yield put({
            type: FOLDERS.handlers.askCreate,
            askCreate: true,
          });
        }
      }
      const allFolders = { ...results, ...foldersStore };
      const cleanedResults = connectors.includes(category)
        ? results
        : arrayToCollection(
            compact(
              Object.values(results ?? {}).map(
                ({ id, permissions: p, inheritPermissions, ...rest }) => {
                  const permissions = getItemPermissions(p || [], id, allFolders, user);
                  const isFolderHasPermission = hasPermissionToView(p || [], user);
                  const cleanPermissions = isFolderHasPermission ? p : permissions;

                  const allowView = hasPermissionToView(cleanPermissions, user);
                  if (allowView || isXbotAccount || isAdmin) {
                    set(allFolders, `${id}.permissions`, cleanPermissions);
                    return {
                      ...rest,
                      id,
                      permissions: cleanPermissions,
                      inheritPermissions: inheritPermissions || !isFolderHasPermission,
                    };
                  }
                  return undefined;
                }
              )
            ),
            'id'
          );
      yield* merge(cleanedResults);
    } catch (error) {
      Notification.error(formatError(error));
      yield* merge([]);
    }
  }
}

function* createFolder({ parent, data, onSuccess }) {
  const { id: unitID, legacyID } = (yield select())?.user?.activeUnit ?? {};
  const folders = (yield select())?.folders?.data ?? {};
  const rootParent = getRootParent(
    parent,
    folders,
    (folders[parent]?.shares ?? []).includes(legacyID || unitID)
  );
  try {
    const cleanData = cloneDeep(data);
    if (cleanData.extend) {
      cleanData.extend = Object.entries(cleanData.extend).map(([unit, time]) => ({
        unit,
        time,
      }));
    }
    const { createFolder: folder } = yield graphQLCaller(
      'folders',
      `
          mutation createFolder($unitID: String!, $parent: String!, $folder: InformationInput!, $rootParent: String) {
            createFolder(unitID: $unitID, parent: $parent, folder: $folder, rootParent: $rootParent) {
              id
              unit
              name
              created {
                user
                time
              }
              size
              parent
              permissions {
                group
                permissions
              }
              storeType
              shares
              reports
              expire
              extend {
                unit
                time
              }
            }
          }
        `,
      {
        unitID,
        parent,
        folder: cleanData,
        rootParent,
      }
    );
    const { id } = folder || {};
    if (id) {
      folder.permissions = folders?.[parent]?.permissions ?? [];
      if (folder.extend) {
        const ext = {};
        folder.extend.forEach(({ unit, time }) => {
          ext[unit] = time;
        });
        folder.extend = ext;
      }
      yield* update(id, folder);
      Notification.success('Tạo thư mục thành công.', { action: onSuccess });
    } else {
      yield* update();
      Notification.error('Tạo thư mục không thành công.');
    }
  } catch ({ message }) {
    yield* update();
    Notification.error(message);
  }
}

function* createFromTemplate({ parent, folders, onSuccess }) {
  const unitID = (yield select())?.user?.activeUnit?.id ?? '';
  try {
    yield graphQLCaller(
      'folders',
      `
          mutation createFromTemplate($unitID: String!, $parent: String!, $folders: [CreateFolderInput]!) {
            createFromTemplate(unitID: $unitID, parent: $parent, folders: $folders)
          }
        `,
      {
        unitID,
        parent,
        folders,
      }
    );
    Notification.success('Tạo thư mục thành công.', { action: onSuccess });
    yield put({
      type: FOLDERS.handlers.get,
    });
  } catch ({ message }) {
    yield* update();
    Notification.error(message);
  }
}

function* updateFolder({ id, data, related, onComplete }) {
  const { id: unitID, legacyID: oldID } = (yield select())?.user?.activeUnit ?? {};
  const folders = (yield select())?.folders?.data ?? {};
  try {
    const updateIds = Array.isArray(id) && id.length > 1 ? [...id] : [id];
    const ids = related ? getRelatedIDs(id, Object.values(folders)) : updateIds;
    const cleanData = cloneDeep(data);
    if (data.extend) {
      cleanData.extend = Object.entries(data.extend).map(([uid, time]) => ({
        unit: uid,
        time,
      }));
    }
    if (!data.expire) {
      unset(cleanData, 'expire');
    }
    const missing = [...(data?.shares ?? []), ...(data?.reports ?? [])];
    yield graphQLCaller(
      'folders',
      `
      mutation modifyFolders($unitID: String!, $folders: [FolderInput]) {
          modifyFolders(unitID: $unitID, folders: $folders)
        }
      `,
      {
        unitID,
        folders: ids.map((tid) => ({
          id: tid,
          information: cleanData,
          rootParent: getRootParent(
            tid,
            folders,
            (folders?.[tid]?.shares ?? []).includes(oldID || unitID)
          ),
        })),
      }
    );
    yield* ids.map((cid) => update(cid, data));
    if (missing.length) {
      const { getProductUnits } = yield graphQLCaller(
        'units',
        `
          query getProductUnits($units: [String]!) {
            getProductUnits(units: $units) {
              id
              name
              legacyID
              type
              district
            }
          }
        `,
        {
          units: uniq(missing.flat()),
        }
      );
      const tmp = {};
      getProductUnits.forEach(({ id: uid, name, legacyID, type, district }) => {
        tmp[uid] = { name, legacyID, type, district };
      });
      yield put({
        type: FOLDERS.units,
        units: tmp,
      });
    }
    Notification.success('Cập nhật thư mục thành công.', { action: onComplete });
  } catch ({ message }) {
    yield* update();
    Notification.error(message, { action: () => onComplete(message) });
  }
}
function* cleanUpShares({ ids, removedShares = [], onComplete }) {
  const unitID = (yield select())?.user?.activeUnit?.id ?? '';
  const folders = (yield select())?.folders?.data ?? {};
  try {
    yield graphQLCaller(
      'folders',
      `
      mutation modifyFolders($unitID: String!, $folders: [FolderInput]) {
          modifyFolders(unitID: $unitID, folders: $folders)
        }
      `,
      {
        unitID,
        folders: ids.map((tid) => ({
          id: tid,
          information: { removedShares, shares: folders?.[tid]?.shares ?? [] },
        })),
      }
    );
    const cleanUpSharesFolders = {};
    ids.forEach((id) => {
      const currentShares = folders?.[id]?.shares ?? [];
      cleanUpSharesFolders[id] = {
        ...(folders?.[id] ?? {}),
        shares: currentShares.filter((unit) => !removedShares.includes(unit)),
      };
    });
    yield* merge(cleanUpSharesFolders);

    Notification.success('Cập nhật thư mục thành công.', { action: onComplete });
  } catch ({ message }) {
    yield* update();
    Notification.error(message || 'Đã xảy ra lỗi, vui lòng thử lại sau', {
      action: () => onComplete(message || 'Đã xảy ra lỗi, vui lòng thử lại sau'),
    });
  }
}

function* removeFolders({ parent, ids, onComplete }) {
  const unitID = (yield select())?.user?.activeUnit?.id ?? '';
  const folders = (yield select())?.folders?.data ?? {};
  const folderIds = uniq(flattenDeep(ids.map((id) => getRelatedIDs(id, Object.values(folders)))));
  const rootParent = getRootParent(parent, folders);
  try {
    yield graphQLCaller(
      'folders',
      `
        mutation removeFolders($unitID: String!, $folders: [String]!, $rootParent: String) {
          removeFolders(unitID: $unitID, folders: $folders, rootParent: $rootParent)
        }
      `,
      {
        unitID,
        folders: folderIds,
        rootParent,
      }
    );
    yield* folderIds.map((cid) => update(cid));
    Notification.success('Xoá thư mục thành công.', { action: onComplete });
  } catch ({ message }) {
    yield* update();
    Notification.error(message, { action: () => onComplete(message) });
  }
}

function* cleanFolders({ ids }) {
  const unitID = (yield select())?.user?.activeUnit?.id ?? '';
  try {
    yield graphQLCaller(
      'folders',
      `
        mutation removeFolders($unitID: String!, $folders: [String]!) {
          removeFolders(unitID: $unitID, folders: $folders)
        }
      `,
      {
        unitID,
        folders: ids,
      }
    );
    yield* ids.map((cid) => update(cid));
    Notification.success('Xoá thư mục thành công.');
  } catch ({ message }) {
    yield* update();
    Notification.error(message);
  }
}

function* updatePermissions({ id, permissions, related, onSuccess }) {
  const unitID = (yield select())?.user?.activeUnit?.id ?? '';
  const legecyID = (yield select())?.user?.activeUnit?.legacyID ?? '';
  const folders = (yield select())?.folders?.data ?? {};
  const rootParent = getRootParent(
    id,
    folders,
    (folders[id]?.shares ?? []).includes(legecyID || unitID)
  );
  const shoudldApplyParentShares =
    (folders[id]?.shares ?? []).includes(legecyID || unitID) &&
    (traceToRoot(id, folders).length > 1 || related);
  try {
    const relatedIds = getRelatedIDs(id, Object.values(folders));
    const ids = related ? relatedIds : [id];
    const { setPermissions } = yield graphQLCaller(
      'folders',
      `
        mutation setPermissions($unitID: String!, $folders: [String]!, $permissions: [PermissionInput], $rootParent: String, $shoudldApplyParentShares: Boolean) {
          setPermissions(unitID: $unitID, folders: $folders, permissions: $permissions, rootParent: $rootParent, shoudldApplyParentShares: $shoudldApplyParentShares)
        }
      `,
      {
        unitID,
        folders: related ? ids.filter((cid) => cid !== id) : ids,
        permissions: related ? [] : permissions,
        rootParent,
        shoudldApplyParentShares,
      }
    );
    if (related) {
      yield graphQLCaller(
        'folders',
        `
          mutation setPermissions($unitID: String!, $folders: [String]!, $permissions: [PermissionInput]) {
            setPermissions(unitID: $unitID, folders: $folders, permissions: $permissions)
          }
        `,
        {
          unitID,
          folders: [id],
          permissions,
          rootParent,
        }
      );
    }
    if (setPermissions) {
      yield* ids.map((cid) => update(cid, { permissions }));
      Notification.success('Cập nhật phân quyền thành công.', { action: onSuccess });
      if (!related) {
        const updateFolders = arrayToCollection(
          relatedIds
            .filter((cid) => cid !== id)
            .map((cid) => {
              const { permissions: folderPermissions } = folders[cid] ?? {};
              const newPermissions = [...folderPermissions, ...permissions].map((value) => {
                const sameGroup = folderPermissions.find(
                  (p) => p.group === value.group || value.group === 'staffs'
                );

                if (!sameGroup) {
                  return value;
                }
                return sameGroup;
              });
              return {
                ...folders[cid],
                permissions: newPermissions,
              };
            }),
          'id'
        );
        yield* merge(updateFolders);
      }
    } else {
      yield* update();
      Notification.error('Cập nhật phân quyền không thành công.');
    }
  } catch ({ message }) {
    yield* update();
    Notification.error(message);
  }
}

function* getFiles({ folderId, rootParent }) {
  const connectors = (yield select())?.user?.activeUnit?.products?.fbot?.connectors ?? [];
  const unit = (yield select())?.user?.activeUnit ?? {};
  const unitFolders = Object.values((yield select())?.folders.data ?? {}).filter(
    ({ parent, unit: fUnit }) => parent === 'unit' && fUnit === unit.id
  );
  // eslint-disable-next-line no-restricted-syntax
  for (const connector of connectors) {
    if (unit.products?.[connector]) {
      yield put({
        type: FOLDERS.handlers.get,
        category: connector,
      });
    }
  }

  if (folderId) {
    yield put({
      type: FILES.handlers.get,
      folder: folderId,
      rootParent,
    });
    yield all(unitFolders.map(({ id }) => put({ type: FILES.handlers.get, folder: id })));
  }
}

export const handleGetFolders = (category, unit, folderParent, rootParent) => ({
  type: FOLDERS.handlers.get,
  category,
  folderParent,
  unit,
  rootParent,
});

export const handleCreateFolder = (parent, data, onSuccess) => ({
  type: FOLDERS.handlers.create,
  parent,
  data,
  onSuccess,
});

export const handleCreateFolderFromTemplate = (parent, folders, onSuccess) => ({
  type: FOLDERS.handlers.template,
  parent,
  folders,
  onSuccess,
});

export const handleUpdateFolder = (id, data, related = false, onComplete) => ({
  type: FOLDERS.handlers.update,
  id,
  data,
  related,
  onComplete,
});
export const handleCleanupSharesFolders = (ids, removedShares, onComplete) => ({
  type: FOLDERS.handlers.cleanUpShares,
  ids,
  removedShares,
  onComplete,
});

export const handleRemoveFolders = (parent, ids, onComplete) => ({
  parent,
  type: FOLDERS.handlers.remove,
  ids,
  onComplete,
});

export const handleUpdatePermissions = (
  id,
  permissions,
  related = false,
  onSuccess = undefined
) => ({
  type: FOLDERS.handlers.permissions,
  id,
  permissions,
  related,
  onSuccess,
});

export const handleChangeYear = (year, folderId = '', rootParent) => ({
  type: FOLDERS.handlers.year,
  year,
  folderId,
  rootParent,
});

export const handleShowHidden = () => ({
  type: FOLDERS.showHidden,
});

export const handleCleanFolders = (ids) => ({
  type: FOLDERS.handlers.clean,
  ids,
});

export const handleFoldersViewChanged = (folder, search, reportedUnit) => ({
  type: FOLDERS.handlers.view,
  folder,
  search,
  reportedUnit,
});

export const handleCloseCreateFolders = () => ({
  type: FOLDERS.handlers.askCreate,
  askCreate: false,
});

export default function* saga() {
  yield all([
    yield takeEvery(FOLDERS.handlers.get, getFolders),
    yield takeEvery(FOLDERS.handlers.create, createFolder),
    yield takeEvery(FOLDERS.handlers.template, createFromTemplate),
    yield takeEvery(FOLDERS.handlers.remove, removeFolders),
    yield takeEvery(FOLDERS.handlers.clean, cleanFolders),
    yield takeEvery(FOLDERS.handlers.update, updateFolder),
    yield takeEvery(FOLDERS.handlers.cleanUpShares, cleanUpShares),
    yield takeEvery(FOLDERS.handlers.permissions, updatePermissions),
    yield takeEvery(FOLDERS.handlers.year, getFiles),
  ]);
}
