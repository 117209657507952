import {
  AutoComplete,
  DatePickerInput,
  Notification,
  Panel,
  Waiting,
} from '@xbotvn/react-ui/components';
import { Box, Divider, Grid, Icon, Typography } from '@xbotvn/react-ui/core';
import { isEqual } from '@xbotvn/utils/collection';
import { endOfDay, format, startOfDay } from '@xbotvn/utils/date';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { graphQLCaller } from '../../libs/backend';

function UsersLogs({ onClose, type, id }) {
  const unitId = useSelector(({ user }) => user.activeUnit?.id ?? '');

  const [logs, setLogs] = useState([]);
  const [filter, setFilter] = useState({});
  const [handling, setHandling] = useState(false);

  const newLogs = useMemo(
    () => [...logs].sort((a, b) => new Date(b.time) - new Date(a.time)),
    [logs]
  );

  const users = useMemo(() => [...new Set(logs.map((log) => log.user))], [logs]);

  const items = useMemo(() => {
    const filterUser = filter.user;
    const filterDate = filter.date;

    if (!filterUser && !filterDate) return newLogs;

    return newLogs.filter((log) => {
      if (filterUser && log.user !== filterUser) return false;
      if (filterDate) {
        return log.time && isEqual(filterDate, startOfDay(log.time));
      }
      return true;
    });
  }, [newLogs, filter]);

  const fetchLogs = async () => {
    if (!unitId) {
      onClose();
      return;
    }

    try {
      const { from, to } = filter;
      let query = `{
        logs(unitID: "${unitId}", type: "${type}", ref: "${id !== 'unit' ? id : ''}") {
          user
          message
          time
        }
      }`;

      if (from && to) {
        query = `{
          logs(unitID: "${unitId}", type: "${type}", ref: "${
          id !== 'unit' ? id : ''
        }", from: ${startOfDay(from).getTime()}, to: ${endOfDay(to).getTime()}) {
            user
            message
            time
          }
        }`;
      }

      if ((!from && !to) || (from && !to)) setHandling(true);

      const results = await graphQLCaller('logs', query);
      setLogs(
        (results?.logs ?? []).map((log) => ({
          ...log,
          time: log.time ?? '',
        }))
      );
      setHandling(false);
    } catch ({ message }) {
      Notification.error(message);
      setHandling(false);
    }
  };

  useEffect(() => {
    fetchLogs();
  }, [filter]);

  return (
    <>
      {handling ? <Waiting fullscreen /> : null}
      <Panel open onClose={onClose} anchor="right" title="Lịch sử">
        <Box width={560} maxWidth="100vw">
          <Box
            width={540}
            maxWidth="90%"
            style={{
              padding: '12px 0px',
              top: 65,
              position: 'fixed',
              background: 'white',
            }}
            ml={2}
          >
            <Grid container spacing={1}>
              <Grid container item xs={12}>
                <Grid item xs={6} sm={6}>
                  <DatePickerInput
                    inputProps={{
                      label: 'Từ ngày',
                      fullWidth: true,
                    }}
                    style={{
                      width: '100%',
                    }}
                    value={filter.from || null}
                    onDayChange={(d) =>
                      setFilter((prevFilter) => ({
                        ...prevFilter,
                        from: d ? startOfDay(d) : null,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <DatePickerInput
                    inputProps={{
                      label: 'Đến ngày',
                      fullWidth: true,
                    }}
                    style={{
                      width: '100%',
                    }}
                    value={filter.to || null}
                    onDayChange={(d) =>
                      setFilter((prevFilter) => ({
                        ...prevFilter,
                        to: d ? startOfDay(d) : null,
                      }))
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <AutoComplete
                  value={filter.user || null}
                  options={users}
                  onChange={(e, val) => {
                    setFilter((prevFilter) => ({
                      ...prevFilter,
                      user: val,
                    }));
                  }}
                  fullWidth
                  inputProps={{
                    label: 'Tài Khoản',
                    placeholder: 'chọn tài khoản',
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          {items.length ? (
            <Box mt={24} ml={2}>
              {items.map(({ user, time, message }, index) => (
                <Box m={1} key={`${time + index}_${user}_${message}`} width={520} maxWidth="90%">
                  <Grid container spacing={1}>
                    <Grid item fontWeight="bold" xs={6} sm={4}>
                      <Typography
                        style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}
                      >
                        <Icon style={{ fontSize: '1rem', marginRight: '5px' }}>history</Icon>
                        {format(time, 'dd/MM/y hh:mm:ss')}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={8}>
                      <Typography
                        variant="caption"
                        style={{
                          fontWeight: 'bold',
                          display: 'flex',
                          alignItems: 'center',
                          paddingLeft: '10px',
                        }}
                      >
                        <Icon style={{ fontSize: '1rem', marginRight: '5px' }}>perm_identity</Icon>
                        {user}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography style={{ marginBottom: 20 }}>{message}</Typography>
                  <Divider />
                </Box>
              ))}
            </Box>
          ) : null}
        </Box>
      </Panel>
    </>
  );
}

UsersLogs.propTypes = {
  onClose: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default UsersLogs;
