import { uniq, uniqWith } from '@xbotvn/utils/collection';

export const hasPermission = (permission, permissions, user) => {
  const groups = user.activeUnit?.products?.fbot?.groups ?? {};
  const email = user.email ?? '';
  if (user.isAdmin) return true;
  const validGroups = ['staffs', email];
  Object.entries(groups).forEach(([group, info]) => {
    if ((info.staffs ?? []).includes(email)) validGroups.push(group);
  });
  const found = validGroups.some((gid) => {
    const groupPermissions = permissions.find(({ group }) => group === gid);
    if (groupPermissions) {
      return (groupPermissions?.permissions ?? []).includes(permission);
    }
    return false;
  });
  return found;
};

export const hasPermissionToView = (permissions, user) => {
  const groups = user.activeUnit?.products?.fbot?.groups ?? {};
  const email = user.email ?? '';
  return (permissions || []).some(
    ({ group }) =>
      (group || []).includes(email) ||
      (groups?.[group]?.staffs ?? []).includes(email) ||
      group === 'staffs'
  );
};

export const traceToRoot = (id, folders) => {
  const { parent, name } = folders?.[id] || {};
  if (!parent || !name) return [];
  return [...traceToRoot(parent, folders), { id, name }];
};

export const getRootParent = (id, folders = {}, isSharedFolder = false) => {
  const { parent } = folders?.[id] || {};
  if (folders[parent]) {
    const { rootShares, inheritPermissions } = folders[parent] ?? {};
    if (isSharedFolder) {
      if (rootShares && !inheritPermissions && inheritPermissions !== undefined) {
        return parent;
      }
      return getRootParent(parent, folders, isSharedFolder);
    }
    if (!inheritPermissions && inheritPermissions !== undefined) return parent;
    return getRootParent(parent, folders, isSharedFolder);
  }
  return id;
};

export const getNeartParentPermission = (id, folders, user) => {
  const { parent } = folders?.[id] || {};
  const { permissions } = folders?.[parent] || {};

  if (folders[parent]) {
    if ((hasPermissionToView(permissions, user) || user.isAdmin) && (permissions || []).length) {
      return permissions;
    }
    return getNeartParentPermission(parent, folders, user);
  }
  return [];
};

export const getItemPermissions = (permissions, id, folders, user) => {
  const unitID = user?.activeUnit?.legacyID || (user?.activeUnit?.id ?? '');
  const folderPermissions = permissions || [];
  const nearestParentPermissions = getNeartParentPermission(id, folders, user) || [];
  const rootItemFolder = getRootParent(id, folders, (folders?.[id]?.shares ?? []).includes(unitID));
  const parentPermissions = hasPermissionToView(nearestParentPermissions, user)
    ? nearestParentPermissions
    : folders[rootItemFolder]?.permissions ?? [];

  const cleanParentPermissions = [
    ...nearestParentPermissions,
    ...(folders[rootItemFolder]?.permissions ?? []),
  ].map((value) => {
    const sameGroup = nearestParentPermissions.find(
      (p) => p.group === value.group || value.group === 'staffs'
    );

    if (!sameGroup) {
      return value;
    }
    return sameGroup;
  });

  const allPermissions = user?.isAdmin
    ? [...folderPermissions, ...cleanParentPermissions]
    : [...parentPermissions, ...folderPermissions];

  const result = allPermissions.map((value) => {
    const sameGroup = folderPermissions.find(
      (p) => p.group === value.group || value.group === 'staffs'
    );

    if (!sameGroup) {
      return value;
    }
    return sameGroup;
  });
  return uniqWith(result, ['group', 'permissions']);
};

export const findChildFolders = (fid, folders = []) => {
  const child = folders.filter(({ parent, id }) => parent === fid && id !== fid);
  return [...child, ...child.map(({ id }) => findChildFolders(id, folders))].flat(Infinity);
};
export const oneNodeTypes = ['tinh', 'sgd', 'thpt', 'lc123', 'lcmn123', 'lc23'];

export const unitTypeOrder = {
  tinh: {
    order: 1,
  },
  sgd: {
    order: 2,
  },
  thpt: {
    order: 3,
  },
  quan: {
    order: 4,
  },
  pgd: {
    order: 5,
  },
  phuong: {
    order: 6,
  },
  thcs: {
    order: 7,
  },
  th: {
    order: 8,
  },
  mn: {
    order: 9,
  },
  lcmn123: {},
  lc123: {},
  lc23: {},
};

export function getCommonSharesFolders(id, folders, shares = []) {
  const children = folders.filter(
    ({ parent, id: cid, shares: cShares }) =>
      parent === id && id !== cid && (cShares || []).some((unit) => shares.includes(unit))
  );
  if (children.length) {
    return uniq([
      ...children.map(({ id: cid }) => [cid, ...getCommonSharesFolders(cid, folders)]).flat(),
    ]);
  }
  return [];
}
