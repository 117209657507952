import { Notification } from '@xbotvn/react-ui/components';
import { all, put, select, takeEvery } from 'redux-saga/effects';

import { callAPI } from '../../libs/backend';
import { CATALOGS } from './constants';

function* querySystem(id) {
  yield put({
    type: CATALOGS.handlers.system,
    id,
  });
}

function* updateSystem(id, data) {
  yield put({
    type: CATALOGS.system,
    id,
    data,
  });
}

function* updateApp(id, data) {
  yield put({
    type: CATALOGS.app,
    id,
    data,
  });
}

function* querySystemCatalogs() {
  try {
    yield* querySystem('cities');
    const cities = yield callAPI('api/cities');
    yield* updateSystem('cities', cities || {});
  } catch ({ message }) {
    Notification.error(message);
    yield* updateSystem('cities', {});
  }

  try {
    yield* querySystem('product');
    const product = yield callAPI('api/product');
    yield* updateSystem('product', product || {});
  } catch ({ message }) {
    Notification.error(message);
    yield* updateSystem('product', {});
  }

  try {
    yield* querySystem('settings');
    const settings = yield callAPI('api/settings');
    yield* updateSystem('settings', settings || {});
  } catch ({ message }) {
    Notification.error(message);
    yield* updateSystem('settings', {});
  }
}

function* queryAppCatalogs(unitId) {
  try {
    yield put({
      type: CATALOGS.handlers.app,
      id: 'labels',
    });
    const catalogs = yield callAPI('api/catalogs', { unit: unitId });
    yield* updateApp('labels', catalogs?.labels ?? []);
  } catch ({ message }) {
    Notification.error(message);
    yield* updateApp('labels', []);
  }
}

function* updateAppCatalog({ id, data, onComplete }) {
  const { activeUnit } = (yield select())?.user ?? {};
  const prevData = (yield select()).catalogs?.app?.[id]?.data;
  if (!activeUnit?.id) yield* updateApp(id, prevData);
  try {
    yield callAPI('api/updateCatalog', {
      unit: activeUnit.id,
      catalog: id,
      items: data,
    });
    yield* updateApp(id, data);
    Notification.success('Cập nhật thành công.', { action: onComplete });
  } catch ({ message }) {
    Notification.error(message, { action: onComplete(message) });
    yield* updateApp(id, prevData);
  }
}

export function* queryCatalogs(unitId) {
  yield* querySystemCatalogs();
  yield* queryAppCatalogs(unitId);
}

export const handleUpdateCatalog = (id, data, onComplete) => ({
  type: CATALOGS.handlers.update,
  id,
  data,
  onComplete,
});

export default function* saga() {
  yield all([yield takeEvery(CATALOGS.handlers.update, updateAppCatalog)]);
}
