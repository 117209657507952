import { omit } from '@xbotvn/utils/collection';

import { FILES, SIGN_OUT } from '../actions/constants';

const initialState = {
  data: {},
  search: {},
  handling: false,
  uploading: false,
  process: 0,
};

export default function files(state = initialState, action) {
  const { folder, data, file, type, query, options, process } = action;

  switch (type) {
    case FILES.handlers.search:
      if (query) {
        return {
          ...state,
          search: {
            query,
            options,
            data: {},
          },
          handling: true,
        };
      }
      return {
        ...state,
        search: {},
      };
    case FILES.handlers.get:
    case FILES.handlers.update:
    case FILES.handlers.remove:
      return {
        ...state,
        handling: true,
      };

    case FILES.handlers.upload:
      return {
        ...state,
        uploading: true,
      };
    case FILES.handlers.process: {
      return {
        ...state,
        process,
      };
    }
    case FILES.search:
      return {
        ...state,
        search: {
          ...(state.search ?? {}),
          data,
        },
        handling: false,
      };
    case FILES.update:
      return {
        ...state,
        data: {
          ...(state?.data ?? {}),
          [folder]: data,
        },
        handling: false,
        uploading: false,
        process: 0,
      };
    case FILES.file.update:
      // eslint-disable-next-line no-case-declarations
      const fileKeys = Object.keys(data);
      return {
        ...state,
        data: {
          ...(state?.data ?? {}),
          [folder]: {
            ...(state?.data?.[folder] ?? {}),
            [file]: {
              ...omit(state?.data?.[folder]?.[file] ?? {}, fileKeys),
              ...data,
            },
          },
        },
        handling: false,
      };
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}
