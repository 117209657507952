import { Backdrop, Box, Typography } from '@xbotvn/react-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import * as S from './styles';

const LoadingProgress = ({ value, ...props }) => (
  <Backdrop open style={{ zIndex: 9999, color: '#fff' }}>
    <S.Wrapper>
      <Box style={{ width: '50%', mb: 1 }}>
        <S.Progress variant="buffer" value={value} {...props} />
      </Box>
      <Box>
        <Typography variant="body2" color="text.secondary">
          Đang tải lên: {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </S.Wrapper>
  </Backdrop>
);

LoadingProgress.propTypes = {
  value: PropTypes.number.isRequired,
};

export default LoadingProgress;
