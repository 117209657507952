import { Notification } from '@xbotvn/react-ui/components';
import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { auth, graphQLCaller, signedOut } from '../../libs/backend';
import { app } from '../../libs/firebase';
import { queryCatalogs } from './catalogs';
import { FOLDERS, SIGN_OUT, USER } from './constants';

export function* update(data = {}) {
  yield put({
    type: USER.update,
    ...data,
  });
}

export function* loadLiveSupport(user, unit) {
  const cities = (yield select())?.catalogs?.system?.cities?.data ?? {};
  if (window.fcWidget) {
    const title = user.gender === 'M' ? 'Thầy' : 'Cô';

    const province = cities?.[unit.province] ?? {};
    const district = province?.districts?.[unit.district] ?? {};
    const ward = district?.wards?.[unit.ward] ?? {};

    window.fwcrm.on('user:created', () => {
      window.fcWidget.setExternalId(user.uid);
      window.fcWidget.user.setFirstName(title);
      window.fcWidget.user.setLastName(user.displayName);
      window.fcWidget.user.setEmail(user.email);
      window.fcWidget.user.setProperties({
        cf_unit_name: unit.name,
        cf_unit_id: unit.id,
        cf_province: province?.name ?? unit?.province,
        cf_district: district?.name ?? unit?.district,
        cf_ward: ward?.name ?? unit?.ward,
        cf_role: unit.isAdmin ? 'Admin' : 'Staff',
      });
    });
  }
}

function* userSignedIn(user) {
  const { email, uid } = user;
  if (email) {
    try {
      const { activeUnit, ...rest } = yield auth(email, uid);
      const found = (rest.units ?? []).find(({ id }) => id === activeUnit);
      const isXbotAccount = Object.keys(rest.xbot?.roles ?? {}).length > 0;
      const isAdmin =
        isXbotAccount ||
        (found?.admins ?? []).includes(email) ||
        (found?.products?.fbot?.admins ?? []).includes(email);
      const isStaff = isAdmin || (found?.products?.fbot?.staffs ?? []).includes(email);
      if (found && (isStaff || isAdmin || isXbotAccount)) {
        found.legacyID = found.products?.fbot?.id ?? found.id;
        yield* update({
          ...rest,
          activeUnit: found,
          uid,
          email,
          displayName: user?.displayName ?? email,
          photoURL: user?.photoURL ?? '',
          authorizing: false,
          isStaff,
          isAdmin,
          isXbotAccount,
        });
        yield* queryCatalogs(found.id);
        yield* loadLiveSupport(user, found);
        yield put({
          type: FOLDERS.handlers.get,
          category: '',
        });
        yield put({
          type: FOLDERS.handlers.get,
          category: 'reports',
        });
        yield put({
          type: FOLDERS.handlers.get,
          category: 'shares',
        });
        const configConnectors =
          (yield select())?.catalogs?.system?.product?.data?.config?.connectors ?? [];
        const connectors = Object.keys(found.products).filter(
          (product) => product !== 'fbot' && configConnectors.includes(product)
        );
        if (connectors.length) found.products.fbot.connectors = connectors;
        yield* update({ activeUnit: found });
        // eslint-disable-next-line no-restricted-syntax
        for (const connector of connectors) {
          yield put({
            type: FOLDERS.handlers.get,
            category: connector,
          });
        }
      } else {
        Notification.warn(
          'Email của Thầy/Cô chưa được cấp quyền vào đơn vị. Thầy/Cô vui lòng liên hệ quản trị phụ trách phần mềm để được cấp quyền vào đơn vị. Mọi vướng mắc vui lòng liên hệ tổng đài CSKH 028 73003588 để được giải đáp. Trân trọng!',
          {
            autoClose: 10000,
          }
        );
        yield put({
          type: USER.handlers.signOut,
        });
      }
    } catch ({ message }) {
      yield* update({ email, uid, authorizing: false });
      Notification.error(message);
    }
  } else {
    yield* update({ authorizing: false });
  }
}

function* userSignedOut() {
  yield app.auth().signOut();
  yield signedOut();
  yield put({
    type: SIGN_OUT,
  });
}

function* updateInformation({ information, onComplete }) {
  const { email, uid, male, phoneNumber, signature } = (yield select())?.user ?? {};
  const activeUnit = information?.activeUnit;
  try {
    if (activeUnit) {
      yield graphQLCaller(
        'users',
        `
      mutation updateUserInformation($uid: ID!, $information: UserInput!) {
        updateUserInformation(uid: $uid, information: $information)
      }
    `,
        {
          uid,
          information: {
            email,
            male,
            phoneNumber,
            signature,
            activeUnit,
          },
        }
      );
      yield* update(information);
      Notification.success('Chuyển đơn vị thành công.', { action: onComplete });
    } else {
      yield graphQLCaller(
        'users',
        `
      mutation updateUserInformation($uid: ID!, $information: UserInput!) {
        updateUserInformation(uid: $uid, information: $information)
      }
    `,
        {
          uid,
          information: {
            email,
            ...information,
          },
        }
      );
      yield* update(information);
      Notification.success('Cập nhật thông tin tài khoản thành công.', { action: onComplete });
    }
  } catch ({ message }) {
    yield* update();
    Notification.error(message, { action: () => onComplete(message) });
  }
}

function* updateUnit({ admins, staffs, groups, onComplete }) {
  const { activeUnit } = (yield select())?.user ?? {};
  if (!activeUnit?.id) yield* update();

  try {
    yield graphQLCaller(
      'units',
      `
      mutation updateProductStaffs($id: String!, $admins: [String]!, $staffs: [String]!, $groups: [GroupInput]) {
        updateProductStaffs(id: $id, admins: $admins, staffs: $staffs, groups: $groups)
      }
    `,
      {
        id: activeUnit.id,
        admins,
        staffs,
        groups: Object.entries(groups).map(([id, values]) => ({
          id,
          ...values,
        })),
      }
    );

    yield put({
      type: USER.unit,
      admins,
      staffs,
      groups,
    });
    Notification.success('Cập nhật thành công.', { action: onComplete });
  } catch ({ message }) {
    Notification.error(message, { action: onComplete(message) });
    yield* update();
  }
}

export const handleUserUpdate = (information, onComplete) => ({
  type: USER.handlers.update,
  information,
  onComplete,
});

export const handleUserSignedIn = (user) => ({
  type: USER.handlers.login,
  ...user,
});

export const handleUserSignOut = () => ({
  type: USER.handlers.signOut,
});

export const handleUpdateUnit = (staffs, admins, groups, onComplete) => ({
  type: USER.handlers.unit,
  staffs,
  admins,
  groups,
  onComplete,
});

export const handleSwitchUnit = (information, onComplete) => ({
  type: USER.handlers.switch,
  information,
  onComplete,
});

export default function* saga() {
  yield all([
    yield takeLatest(USER.handlers.login, userSignedIn),
    yield takeLatest(USER.handlers.update, updateInformation),
    yield takeEvery(USER.handlers.signOut, userSignedOut),
    yield takeLatest(USER.handlers.unit, updateUnit),
    yield takeLatest(USER.handlers.switch, updateInformation),
  ]);
}
