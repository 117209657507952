import { Box, Button, Typography } from '@xbotvn/react-ui/core';
import styled from 'styled-components';

export const Container = styled(Box)`
  z-index: 998;
  background-color: #394b59;
  color: #f5f8fa;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65px;

  ul {
    list-style: none;
    padding-inline-start: 20px;
    margin: unset;
  }

  ul > li {
    display: flex;
    align-items: center;
  }
  a {
    color: #f5f8fa;
    text-decoration: none;
    display: flex !important;
  }

  @media (max-width: 599px) {
    flex-wrap: wrap;
    align-items: start;
    align-content: end;
    height: 65px;
    justify-content: start;
  }
`;

export const StyledButton = styled(Button)`
  z-index: 999;
  opacity: 0.9;
  border-radius: 4px !important;
  @media (max-width: 599px) {
    &.MuiButton-root {
      bottom: -18px !important;
      right: -8px !important;
    }
  }
`;

export const CopyrightTypography = styled(Typography)`
  @media (min-width: 600px) {
    br {
      display: none;
    }
  }
  @media (max-width: 599px) {
    padding-left: 8px;
    text-align: center;
  }
`;

export const StyledBoxChat = styled(Box)`
  position: absolute;
  right: 12px;
  top: 12px;
  display: flex;
  align-items: center;
  .MuiTypography-root {
    margin-right: 10px !important;
    text-align: center !important;
  }
  @media (max-width: 599px) {
    .MuiTypography-root {
      display: none;
    }
  }
`;

export const StyledBoxContact = styled(Box)`
  position: absolute;
  left: 0;
  top: 4px;
  @media (max-width: 599px) {
    width: 100%;
    ul {
      display: flex;
      justify-content: space-between;
      padding-inline-start: 5px;
      .MuiIcon-root {
        margin-right: 5px !important;
      }
    }
  }
`;
