import { AutoComplete, Notification, Table, Waiting } from '@xbotvn/react-ui/components';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  Typography,
  useMediaQuery,
} from '@xbotvn/react-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { handleFindUnits } from '../../redux/actions/units';
import { handleSwitchUnit } from '../../redux/actions/user';
import Address from './Address';

function Units({ onClose, onSelectChanged, selected, quickAccess }) {
  const dispatch = useDispatch();
  const { unit, support, admin, units, unitTypesOptions, handling, activeUnit } = useSelector(
    ({ user, units: unitsStore, catalogs }) => ({
      unit: user?.activeUnit ?? {},
      support: user?.account?.xbot?.support ?? false,
      admin: user?.account?.xbot?.admin ?? false,
      units: unitsStore?.data ?? {},
      unitTypesOptions: catalogs.system?.settings?.data?.unitTypes ?? {},
      handling: unitsStore?.handling ?? false,
      activeUnit: user?.activeUnit,
    })
  );

  const [filterType, setFilterType] = useState(null);
  const [address, setAddress] = useState({
    province: unit?.province ?? '',
    district: unit?.district ?? '',
    ward: unit?.ward ?? '',
  });
  const [selecting, setSelecting] = useState({});

  const history = useHistory();

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  useEffect(() => {
    if (selected) {
      const tmp = {};
      selected.forEach((unitId) => {
        tmp[unitId] = true;
      });
      setSelecting(tmp);
    }
  }, [selected]);

  useEffect(() => {
    const { province, district, ward } = address;
    if (filterType) {
      const cleanFilter = { type: filterType };
      const unitsLevel = unitTypesOptions?.[unit?.type ?? '']?.level ?? '';
      const filterLevel = unitTypesOptions?.[filterType]?.level ?? '';
      switch (filterLevel) {
        case 'province':
          if (!province) return;
          cleanFilter.province = province;
          break;
        case 'district':
          cleanFilter.province = province;
          if (unitsLevel !== 'province' && !district) return;
          if (district) cleanFilter.district = district;
          break;
        case 'ward':
          cleanFilter.province = province;
          if (!['province', 'district'].includes(unitsLevel) && !ward) return;
          if (district) cleanFilter.district = district;
          if (ward) cleanFilter.ward = ward;
          break;
        default:
      }
      if (cleanFilter.type && cleanFilter.province) {
        dispatch(handleFindUnits(cleanFilter, !support || onSelectChanged));
      }
    }
  }, [filterType, address]);

  const columns = useMemo(() => {
    const cols = [
      {
        Header: 'Tên đơn vị',
        accessor: 'name',
        width: 600,
      },
    ];
    if (quickAccess)
      cols.push({
        Header: '',
        accessor: 'id',
        width: 150,
        // eslint-disable-next-line react/prop-types
        Cell: ({ cell: { value } }) => (
          <Button
            style={{ marginRight: 7 }}
            size="small"
            color="primary"
            onClick={() => {
              if (activeUnit !== value) {
                dispatch(handleSwitchUnit({ activeUnit: value }, () => window.location.reload()));
                history.push(`/folders/${value}/unit`);
              } else Notification.info('Bạn đang ở đơn vị này');
            }}
            startIcon={<Icon>open_in_browser</Icon>}
          >
            Vào đơn vị
          </Button>
        ),
      });
    return cols;
  }, []);

  const data = useMemo(() => {
    const rows = [];
    const unitsLevel = unitTypesOptions?.[unit?.type ?? '']?.level ?? '';
    const filterLevel = unitTypesOptions?.[filterType]?.level ?? '';
    const filterUnits = admin
      ? Object.entries(units)
      : Object.entries(units).filter((e) => !e[1]?.removed);
    const acceptTypes = [filterType, ...(unitTypesOptions?.[filterType]?.expand ?? [])];
    filterUnits.forEach(([id, u]) => {
      if (acceptTypes.includes(u.type)) {
        let passed = true;
        switch (filterLevel) {
          case 'province':
            if (u.province !== address.province) passed = false;
            break;
          case 'district':
            if (u.province !== address.province) passed = false;
            if (!['province'].includes(unitsLevel) || address.district) {
              passed = passed && u.district === address.district;
            }
            break;
          case 'ward':
            if (u.province !== address.province) passed = false;
            if (u.district !== address.district) passed = false;
            if (!['province', 'district'].includes(unitsLevel) || address.ward) {
              passed = passed && u.ward === address.ward;
            }
            break;
          default:
        }
        if (passed) {
          rows.push({
            id,
            ...u,
          });
        }
      }
    });
    return rows;
  }, [units, filterType, address, admin, unit?.type, unitTypesOptions]);

  return (
    <Dialog maxWidth="md" fullWidth open onClose={onClose} fullScreen={!isDesktop}>
      <DialogTitle onClose={onClose} title="Danh Sách Đơn Vị" />
      <DialogContent dividers>
        {handling ? <Waiting fullscreen /> : null}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <AutoComplete
              value={filterType || null}
              options={Object.keys(unitTypesOptions)}
              getOptionLabel={(value) => unitTypesOptions?.[value]?.name}
              onChange={(e, value) => setFilterType(value)}
              inputProps={{
                label: 'Loại Đơn Vị',
                required: true,
              }}
            />
          </Grid>
          {filterType ? (
            <Grid item xs={12}>
              <Address
                onChange={(value) => setAddress(value)}
                level={unitTypesOptions?.[filterType]?.level ?? ''}
                value={address}
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Box width={{ xs: '100%', sm: '910px' }}>
              {data.length ? (
                <Table
                  initialState={{
                    selectedRowIds: selecting,
                  }}
                  onSelect={setSelecting}
                  columns={columns}
                  data={data}
                  height={350}
                  disableFilters
                  disableGroupBy
                  getRowId={(row) => row.id}
                  width={900}
                  rowHeight={56}
                />
              ) : (
                <Box height={350} display="flex" justifyContent="center" alignItems="center">
                  <Typography>
                    Chưa có danh sách đơn vị,
                    <br style={{ display: isDesktop ? 'none' : 'block' }} /> vui lòng thêm/ chọn đơn
                    vị
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {onSelectChanged ? (
          <Button
            color="primary"
            onClick={() => {
              const pending = [];
              Object.entries(selecting).forEach(([unitId, state]) => {
                if (state) {
                  const legacyUnitData = Object.entries(units ?? {}).find(
                    ([uid, { products }]) =>
                      uid === unitId && products.find(({ product, id }) => product === 'fbot' && id)
                  );
                  const selectedID = legacyUnitData
                    ? legacyUnitData?.[1]?.products?.find(({ product }) => product === 'fbot')?.id
                    : unitId;
                  pending.push(selectedID);
                }
              });
              onSelectChanged(pending);
              onClose();
            }}
          >
            Tiếp tục
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
}

Units.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSelectChanged: PropTypes.func,
  selected: PropTypes.arrayOf(PropTypes.string),
  quickAccess: PropTypes.bool,
};

Units.defaultProps = {
  onSelectChanged: undefined,
  selected: [],
  quickAccess: false,
};

export default Units;
